<template>
  <v-form ref="form" @submit.prevent>
    <v-autocomplete 
      :items="items" 
      :item-text="['name']" 
      :item-value="itemValue" 
      v-model="selectedItem" 
      :label="label"
      :rules="[v => requiredRule(v), v => alreadySelectedRule(v)]" 
      :no-data-text="$t(noDataText)"
      :autofocus="autofocus"
      ref="autocomplete"
      @change="setSelectedItem($event, true)"
    >
      <template  v-slot:item="{ attrs, item }" >
				<v-list-item-content>
					<v-list-item-title  >
						<v-tooltip
							bottom
							:disabled="!isTooltipActivated(`${attrs.id}ListItem`)">
							<template v-slot:activator="{ on }" >
								<div
									class="text-truncate"
									v-on="on"
									@mouseover="
										setTooltipActivated($event, `${attrs.id}ListItem`)
									">
									{{ item.name || item }}
								</div>
							</template>
							<span >{{ item.name || item }}</span>
						</v-tooltip>
					</v-list-item-title>
				</v-list-item-content>
			</template>
    </v-autocomplete>
  </v-form>
</template>

<script>
import i18n from "@/plugins/i18n";
import { tools } from "../mixins/tools";

export default {
  name: 'AutocompleteComponent',
  mixins: [tools],
  props: {
    dataSource: { type: Object },
    submitFormComponent: { type: Boolean },
    resetComponent: { type: Boolean },
    currentDataValues: { type: Object },
    config: { type: Object },
    previousStepData: { type: Object },
    valuesPerColumn: { type: Array },
  },
  data() {
    return {
      selectedItem: undefined,
      dirty: false,
      tooltipActivated: {},
      inputDataSource: [],
      requiredRule: v => !!v || i18n.t('required'),
    };
  },
  computed: {
    autofocus() {
      return this.config.autofocus !== false;
    },
    items() {
      return this.config?.usePreviousStepData ? this.inputDataSource : (this.dataSource[this.config.dataSourceAttr] || []);
    },
    itemValue() {
      return [this.config.formAttribute === 'delimiter' ? 'value' : 'id'];
    },
    label() {
      return this.config.indexedLabel || this.$t(this.config.formAttribute + 'Label');
    },
    noDataText(){
      return this.config.noDataText ?? 'noDataAutocomplete'
    },
  },
  methods: {
    alreadySelectedRule(v) {
      if (!this.valuesPerColumn || !this.valuesPerColumn.length) return true;
      return this.valuesPerColumn.filter(el => el === v).length <= 1 || i18n.t('joinKeyDuplicatedError')
    },
    emitIsValidForm() {
      this.$refs.form && this.$emit('isValid', this.$refs.form.validate());
    },
    emitIsDirty() {
      this.$emit('isDirty', { dirty: this.dirty });
    },
    setTooltipActivated(event, ref) {
			const { target } = event;
			this.$set(
				this.tooltipActivated,
				ref,
				target.offsetWidth < target.scrollWidth,
			);
		},
		isTooltipActivated(ref) {
			return this.tooltipActivated[ref];
		},
    // TODO: revisar con la tarea 'Poder ejecutar sólo con teclas del teclado'
    // openMenu() {
    //   this.$nextTick(() => {
    //     if (this.$refs.autocomplete && this.$refs.autocomplete.$refs.menu) {
    //       this.$refs.autocomplete.$refs.menu.isActive = true;
    //     }
    //   });
    // },
    getInputDataSource() {
      const { dataSourceAttr, usePreviousStepData } = this.config;
      let dataBasedOn = null;
      if (usePreviousStepData && this.previousStepData) {
        dataBasedOn = this.previousStepData;
      }
      const dataSource = (
        this.getDataSource(dataBasedOn)[dataSourceAttr] || []
      ).map(({ name, alias }) => (name || alias));
      return dataSource;
    },
    setSelectedItem(v, emit = false) {
      let valueToEmit = null;
      if (v) {
        if (!this.dirty) this.dirty = true;
        if (this.config.formAttribute === 'descriptorId') {
          const item = this.dataSource[this.config.dataSourceAttr].find(e => e.id === v);
          const itemToEmit = { descriptorId: item.id, descriptorName: item.name };
          valueToEmit = {...itemToEmit };
        } else if (this.config.formAttribute) {
          const itemToEmit = { [this.config.formAttribute]: v };
          valueToEmit = {...itemToEmit };
        } else {
          valueToEmit = v;
        }
      }
      if (emit) {
        this.$emit('update', valueToEmit);
        this.emitIsValidForm();
      } else {
        this.selectedItem = v;
      }

    }
  },
  watch: {
    currentDataValues: {
      handler(newValue) {
        const { watchCurrentData, formAttribute } = this.config;
        if (newValue && watchCurrentData) this.setSelectedItem(newValue[formAttribute]);
      },
      deep: true,
    },
    dirty(v) {
      if (v) this.emitIsDirty();
    },
    selectedItem() {
      this.$nextTick(() => this.emitIsValidForm());
    },
    submitFormComponent(v) {
      if (v) {
        this.emitIsValidForm();
        this.$nextTick(() => {
          this.emitIsDirty();
        })
      }
    },
    valuesPerColumn() {
      // TODO refactor: this also forces validation on the empty fields
      this.$nextTick(() => this.emitIsValidForm())
    }
  },
  mounted() {
    this.inputDataSource = this.getInputDataSource()
  },
  created() {
    let value = undefined;
    const { formAttribute } = this.config;

    if (this.currentDataValues && Object.keys(this.currentDataValues).length && formAttribute) {
      const attrToCheck = formAttribute === 'inputsInfo' ? 'descriptorId' : formAttribute;
      value = this.currentDataValues[attrToCheck];
    }
    
    if (value) {
      const { usePreviousStepData } = this.config;
      const isNotPresentOnPreviousData = (this.inputDataSource.length && !this.inputDataSource.find(el => el === value)) || !this.inputDataSource.length;
      if (usePreviousStepData && isNotPresentOnPreviousData) {
        value = undefined;
      }
      this.setSelectedItem(value)
    }
  },
};
</script>
<style lang="scss" scoped>
.v-list {
  max-width: 100%
}
</style>