<template>
  <div>
    <v-dialog :value="itemToImport" :width="900" v-click-outside="cancel" content-class="dialog containerStepper">
      <v-card>
        <v-card-title v-if="itemToImport && importConfig.title || downloadExampleButton" class="import-header">
          <div v-if="importConfig.title">{{ importConfig.title }}</div>
          <v-btn rounded outlined v-if="downloadExampleButton" @click="onDownloadExample()" :loading="loading">
            <v-icon left>{{ downloadExampleButton.icon }}</v-icon>
            {{ downloadExampleButton.text }}
          </v-btn>
        </v-card-title>
        <v-card-text class="text-content">
          <div class="d-flex contenedor" v-if="itemToImport && steps && steps.length">
            <div class="mr-1 left-stepper" min-height="380">
              <v-stepper v-model="currentStep" vertical flat>
                <div v-for="(step, index) in steps" :key="index" class="step-item">
                  <v-stepper-step
                    :step="index + 1"
                    :complete="currentStep > index + 1"
                    @click="selectStep(index + 1)"
                    :style="{ cursor: 'pointer' }"
                  >
                    <div>
                      {{ step.title}}
                    </div>
                    <div v-if="currentStep > index + 1" class="resumeStep">
                      <template v-if="step.resume">
                        <div
                          v-for="(resume, indexResume) in getResumeFields(step.resume.fields)"
                          :key="indexResume"
                          class="itemResumeStep"
                        >
                          <span>{{ resume.label }}</span>
                          {{ setResumeValues(resume) }}
                        </div>
                      </template>
                    </div>
                  </v-stepper-step>
                  <v-stepper-content :complete="currentStep > index + 1" :step="index + 1"> </v-stepper-content>
                </div>
              </v-stepper>
            </div>
            <v-divider vertical></v-divider>
            <div class="ml-1 right-stepper">
              <v-stepper :v-model="currentStep" vertical flat :style="{ overflow: 'auto', 'padding-right': '5px' }">
                <v-stepper-step :complete="false" :step="currentStep" class="ml-2" >
                  {{ steps[currentStep - 1].title }}
                </v-stepper-step>
                <v-stepper-content :step="currentStep">
                  <template v-if="currentStep !== steps.length && steps[currentStep - 1]">
                    <Form
                      v-if="stepFields.length"
                      :key="currentStep"
                      :fields="stepFields"
                      @updatedForm="
                        getStatus = false;
                        submitForm = false;
                        updateForm($event);
                      "
                      :getStatus="getStatus"
                      :currentDataValues="currentDataValues"
                      primaryColor="var(--primary)"
                      :submitForm="submitForm"
                      :resetForm="resetForm"
                      @isValidForm="onIsValidForm"
                      @formStatus="onFormStatus"
                      class="py-2 px-4"
                    />
                  </template>
                  <div v-else>
                    <div class="pb-4 pl-4" :height="'100%'">
                      <ImportFile
                        :submitFormComponent="submitForm"
                        :resetComponent="resetForm"
                        :config="{ allowedExtensionFiles: [this.currentDataValues?.fileType?.toLowerCase()] }"
                        @dirty="dirty = $event"
                        @update="fileSelected"
                        @isValid="isValid = $event"
                      />
                    </div>
                  </div>
                </v-stepper-content>
              </v-stepper>
            </div>
          </div>
        </v-card-text>
        <v-card-actions v-if="itemToImport">
          <slot name="actions">
            <v-btn
              rounded
              outlined
              v-if="importConfig.secondaryButton && currentStep === 1"
              @click="cancel('buttonCancel')"
            >
              <v-icon left>{{ importConfig.secondaryButton.icon }}</v-icon>
              {{ importConfig.secondaryButton.text }}
            </v-btn>
            <v-btn rounded outlined v-else @click="selectStep(currentStep - 1)">
              <v-icon left>mdi-chevron-left</v-icon>
              {{ traduceItem("back") }}
            </v-btn>
            <v-btn
              @click="currentStep === steps.length ? onSubmit() : selectStep(currentStep + 1)"
              color="primary"
              rounded
              depressed
              v-if="importConfig.primaryButton"
            >
              <template v-if="currentStep === steps.length">
                <v-icon left>{{ importConfig.primaryButton.icon }}</v-icon>
                {{ importConfig.primaryButton.text }}
              </template>
              <template v-else>
                {{ traduceItem("continue") }}
                <v-icon right>mdi-chevron-right</v-icon>
              </template>
            </v-btn>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import i18n from "@/plugins/i18n";
import { tools } from "@/mixins/tools";
import { firebaseTools } from "@/mixins/firebase-tools.js";
import ImportFile from "../stepperComponents/ImportFile.vue";
import importConfigStepper from '../mixins/import-config-stepper';

export default {
  name: "ImportModal",
  components: { ImportFile },
  mixins: [tools, importConfigStepper, firebaseTools],
  data: () => ({
    dirty: false,
    getStatus: false,
    resetForm: false,
    submitForm: false,
    selectedFile: null,
    currentStep: 1,
    currentDataValues: {},
    valid: true,
    loading: false,
  }),
  computed: {
    ...mapState(["itemToImport", "userLogged"]),
    importType() {
      return this.itemToImport?.type ?? 'columnsIn';
    },
    steps() {
      return this[this.importType]?.steps || [];
    },
    stepFields() {
      const fields =this.steps[this.currentStep - 1]?.fields || [];
      if (!fields.length) return fields
      return [...fields].map(field => {
        const newField = { ...field }
        if (field.fieldType === 'radioGroup' && this.currentDataValues[field.name]) {
          newField.optionsToCheck = field.optionsToCheck.filter(opt => opt.value === this.currentDataValues[field.name]);
        }
        return newField
      })
    },
    title() {
      return this[this.importType]?.title || '';
    },
    importUrl() {
      return this[this.importType]?.importUrl || '';
    },
    attrToAppendData() {
      return this[this.importType]?.attrToAppendData || '';
    },
    importConfig() {
      return this.itemToImport?.config || {};
    },
    downloadExampleButton() {
      return this[this.importType]?.downloadButton || null;
    }
  },
  methods: {
    ...mapActions(["setItemToImport", "setShowSnackBar", "setLoadingData"]),
    onIsValidForm(event) {
      this.valid = event.valid;
    },
    onFormStatus({dirty}) {
      if (!this.dirty && dirty) this.dirty = true;
    },
    selectStep(targetStep) {
      if (targetStep <= this.currentStep) {
        this.currentStep = targetStep;
      } else {
        this.submitForm = true;
        this.getStatus = true;
        this.$nextTick(() => {
          this.submitForm = false;
          this.getStatus = false;
          if (this.valid && targetStep === (this.currentStep + 1)) {
            this.currentStep = targetStep;
          }
        });
      }
    },
    cancel(event) {
      if (
        !((event.target && event.target.classList.contains("v-overlay__scrim")) || event === "buttonCancel") ||
        !this.itemToImport
      )
        return;

      this.getStatus = true;
      this.$nextTick(() => {
        this.getStatus = false;
        this.executeAction({
          action: "cancel",
          section: "ImportColumns",
          dirty: this.dirty,
        });
      });
    },
    updateForm(event) {
      if (Object.keys(event).length) {
        this.currentDataValues = { ...this.currentDataValues,...event };
      }
    },
    getResumeFields(listOfFields) {
      return listOfFields.filter(resumeConfig => !Object.hasOwnProperty.call(resumeConfig, 'showOnlyIfHasValue') || resumeConfig.showOnlyIfHasValue && this.currentDataValues[resumeConfig.value])
    },
    setResumeValues({value, translateValue, lowerCase}) {
      let valueToReturn = translateValue ? i18n.t(this.currentDataValues[value]) : this.currentDataValues[value];
      if (lowerCase) valueToReturn = valueToReturn.toLowerCase();
      return valueToReturn;
    },
    fileSelected(event) {
      this.selectedFile = event?.selectedFile;
      },
    onSubmit() {
      this.submitForm = true;
      this.$nextTick(() => {
        this.submitForm = false;
        this.submit()
      });
    },
    submit() {
      if (this.isValid && this.selectedFile) {
        const params = this.getParamsToImport();
        let selectedFile = global.structuredClone(this.selectedFile);
        this.setShowSnackBar({
          color: "info",
          icon: "mdi-information",
          msg: i18n.t("uploadedImport", { element: selectedFile.name }),
        });
        this.setLoadingData(true);
        this.httpRequest("post", [window.PROJECT_ENV.BASE_URL_SENDER + this.importUrl, params], false, true)
          .then(async (res) => {
            if (res) {
              await this.setShowSnackBar(false)
              if (res.data?.level !== "SUCCESS") {
                throw(res);
              }
              else {
                this.setShowSnackBar({
                  color: "success",
                  icon: "mdi-check-circle",
                  msg: i18n.t("successfulImport", { element: selectedFile.name }),
                });
              }
            } else {
              this.setLoadingData(false);
              this.loading = false;
            }
          })
          .catch((error) => {
            this.setLoadingData(false);
            this.handleHttpStatus(error);
            this.loading = false;
          })
        this.setItemToImport();
      }
    },
    getParamsToImport() {
      let params = new FormData();
      const dataToSubmit = {
        descriptorId: this.$router.history.current.params.id,
      };
      const { lang = 'es' } = this.userLogged || {};

      Object.keys(this.currentDataValues).forEach((key) => {
        if (this.currentDataValues[key] === null) dataToSubmit[key] = "";
        else dataToSubmit[key] = this.currentDataValues[key];
      });

      params.append(this.attrToAppendData, JSON.stringify(dataToSubmit));
      params.append("lang", lang);
      params.append("file", this.selectedFile);
  
      return params;
      },
      //create fileref
      onDownloadExample() {
        this.loading = true;
        const { lang = 'es' } = this.userLogged || {};
        const { exampleFile } = this[this.importType] || {};
        const fileRef = this.createStorageReference(
          `specifications/${lang}/${exampleFile}`
        );

        this.downloadStorageFile(fileRef, exampleFile).finally(() => {
          this.loading = false;
        });
      }
    },
  watch: {
    itemToImport(val) {
      this.resetForm = this.itemToImport ? false : true;
      this.dirty = false;
      this.getStatus = false;
      this.resetForm = false;
      this.submitForm = false;
      this.selectedFile = null;
      this.currentStep = 1;
      this.currentDataValues = val?.currentDataValues || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content {
  ::v-deep {
    .containerStepper {
      border-radius: 20px;
      min-height: 95vh;
    }
  }
  .text-content {
    height: calc(95vh - 142px);
  }
  .v-card__actions {
    padding: 20px 16px;
  }
}
.left-stepper {
  width: 45%;
  height: calc(95vh - 142px);
  .v-stepper__step {
    padding-left: 20px;
    align-items: baseline;
  }
  .v-stepper__step--complete {
    .resumeStep {
      font-size: 14px;
      margin-bottom: -31px;
      color: var(--fontColor);
      margin-left: -26px !important;
      margin-top: 10px;
      border-left: 1px solid var(--borderGray) !important;
      min-height: 30px;
      padding: 25px 50px 15px 23px;
      span {
        color: var(--fontColorTerciary);
      }
      .itemResumeStep:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
  .step-item:not(:last-child) {
    ::v-deep {
      .v-stepper__content {
        margin-left: 10px !important;
        border-left: 1px solid var(--borderGray) !important;
        min-height: 40px;
      }
    }
  }
}
.right-stepper {
  ::v-deep {
    .v-expansion-panel {
      border-bottom: 1px solid var(--borderGray);
      border-radius: 0px !important;
      &:not(:first-child):after {
        border: none !important;
      }
    }
    ::-webkit-scrollbar {
      height: 7px;
    }
    ::-webkit-scrollbar-track {
      background-color: #f1f2f5;
      transition: background-color 0.3s ease-out;
    }

    ::-webkit-scrollbar {
      width: 5px;
      background-color: #f1f2f5;
      transition: background-color 0.3s ease-out;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 120px;
      background-color: #a2a2a2;
      transition: background-color 0.3s ease-out;
    }
    .labelNewFormEmpty {
      color: var(--fontColorTerciary);
    }
  }
  .alert-container {
    margin-top: 17px;
  }
  width: 51%;
  .titleItemForm {
    color: var(--primary);
    font-weight: 400;
  }
  ::v-deep {
    .v-expansion-panel-header__icon .mdi {
      color: var(--primary) !important;
    }
  }
  .formContainer {
    min-height: 80px;
    ::v-deep {
      .v-input {
        min-height: 77px;
      }
    }
  }
}
.v-stepper--vertical .v-stepper__step {
  padding: 5px 16px 5px 0px;
}
.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin: 0;
  padding: 0;
}
.addDynamicForms {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .titleDynamicForm {
    color: var(--secondary);
  }
}
.contenedor {
  width: 100%;
}
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap,
.headerButton {
  padding: 0;
}
.dynamicForms {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addDynamicFormsGroup {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.itemGroup {
  padding: 20px 0 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
.formGroup {
  margin-top: 20px;
}
.selectedItemGroup {
  background: rgba(0, 0, 0, 0.05);
  color: var(--primary);
  transition: all 0.3s;
}
::v-deep {
  .v-treeview-node__label {
    font-size: 14px;
  }
  .v-treeview-node__checkbox.v-icon {
    color: #ccc;
  }

  .selectFileStep {
    .v-stepper__wrapper {
      margin: -20px 0 0 -20px;
    }
  }

  .v-stepper__step {
    &.v-stepper__step--active .v-stepper__label {
      font-weight: 400;
    }
    
    &.v-stepper__step--active .v-stepper__label, &.v-stepper__step--complete .v-stepper__label {
      color: var(--fontColor);
    }
  
    &.v-stepper__step--inactive .v-stepper__label {
      color: var(--fontColorTerciary);
    }
  }
}

.formContainer {
  form {
    margin-left: 16px;
  }
}

.right-stepper {
  .v-stepper--vertical .v-stepper__step { 
    padding: 0px 16px;
    padding-left: 8px;
  }
}

.v-card > .v-card__title.import-header {
  display: flex;
  flex-direction: row;
  padding: 15px 20px 15px;
  width: 100%;
  > div {
    flex: 1;
  }
}
.import-file{
  margin-top: 20px;
}
</style>
