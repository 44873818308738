 <template>
    <v-skeleton-loader class="stepper">
      <div class="stepper-step" 
          v-for="(step, index) in steps" 
          :class="{ 'stepper-step--last': index === steps.length - 1 }"
          :style="`--step-color: ${step};`" 
          :key="index"
          >
        <div class="d-flex v-skeleton-loader__bone">
            <div class="indicator"></div>
            <div :class="`title title-${index + 1}`"></div>
        </div>
        <div class="stepper-content v-skeleton-loader__bone" v-if="index === 0 " :class="{ 'join-process': $route.name === 'JoinProcess'}">
          <div class="d-flex">
            <div class="big-button-content " ></div>
            <div class="big-button-content" ></div>
            <div class="big-button-content" ></div>
          </div>
          <div class="d-flex v-skeleton-loader__bone">
            <div class="little-button-content"></div>
            <div class="little-button-content"></div>
          </div>
        </div>
      </div>
  </v-skeleton-loader>
</template>
<script>


export default {
  name: 'ExecuteSkeleton',
  data() {
    return {
        steps: ["#99999980", "#99999966", "#9999994D", "#99999933", "#9999991A"]
    };
  }
};
</script> 
<style scoped lang="scss">

 .stepper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      // height: viewport - header - bredcrumbs - padding/margin
      height: calc(100% - 64px - 61px - 40px);
      overflow-y: auto;
    }

    .stepper-step {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 15px;
      min-height: 50px;
      position: relative; 

      &::after {
          content: "";
          position: absolute;
          top: 30px; 
          left: 10px; 
          width: 1px; 
          height: calc(100% - 20px); 
          background-color: var(--borderGray); 
      }
  
      &--last::after {
          display: none;
      }
  
       .indicator {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: var(--step-color);
        flex-shrink: 0;
        margin-right: 12px;
      }
  
       .title {
        width: 191px;
        height: 20px;
        border-radius: 6px;
        background-color: var(--step-color);
        &-2, &-4{
          width: 143px;
        }
      }
    }


    .stepper-content {
      /* 30px margin left */  
      width: calc(100% - 30px);
      height: 201px;
      background-color: var(--menuGray);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      margin-left: 30px;

      .big-button-content {
          height: 105px;
          width: 105px;
          border-radius: 10px;
          background-color: #99999980;
          margin-right: 20px;
      }
      
      &.join-process{
        height: 222px;
        .big-button-content {
          height: 126px;
          width: 240px;
          &:last-child {
              display: none;
          }
        }
      }

      .little-button-content {
          width: 101px;
          height: 36px;
          border-radius: 20px;
          background-color: #99999980;
          margin-right: 10px;
          &:nth-child(2){
            width: 111px;
          }
      } 
    }

</style>