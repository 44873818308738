<template>
  <div class="step__parallel-fields" >
    <v-row class="flex-nowrap" justify="space-between">
      <v-col v-if="config.stepSubtitle">
        <h3 class="formTitle">{{ $t(config.stepSubtitle) }}</h3>
      </v-col>
      <v-col v-if="config.multiRow" class="flex-grow-0">
        <v-btn
          color="primary"
          depressed
          :disabled="addRowDisabled"
          rounded
          @click="rowsCounter += 1"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $t(config.addRowButtonLabel) }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-for="(row, rowIdx) in rowsNumber" :key="`${rowIdx}-${row}-${config.component}`">
      <v-col
        v-for="(field, componentIndex) in config.components"
        :key="`${field.component}-${componentIndex}`"
        :class="{ 'py-0': config.multiRow }"
      >
        <component
          :config="consolidateConfig(field.componentConfig, componentIndex, row)"
          :currentDataValues="getCurrentDataValues(field.componentConfig?.formAttribute, componentIndex, rowIdx)"
          :dataSource="dataSource"
          :is="field.component"
          :secondPreviousData="secondPreviousData?.[`file_${componentIndex}`]"
          :submitFormComponent="submitFormComponent"
          :previousStepData="previousStepData?.[`file_${componentIndex}`]"
          :valuesPerColumn="valuesPerColumn?.[`file_${componentIndex}`]"
          @isValid="onIsValid($event, componentIndex, rowIdx)"
          @isDirty="onDirty($event, componentIndex, rowIdx)"
          @dirty="onDirty($event, componentIndex, rowIdx)"
          @setValuesByDefault="emitValuesByDefault($event, `file_${componentIndex}`, field.componentConfig?.formAttribute)"
          @update="onUpdate($event, `file_${componentIndex}`, field.component, field.componentConfig?.formAttribute, rowIdx)"
        />
      </v-col>
      <v-col align-self="center" class="flex-grow-0" v-if="config.multiRow">
        <v-icon 
          color="primary" 
          :disabled="deleteRowDisabled"
          @click="deleteRow(rowIdx)"
        >mdi-delete</v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AutocompleteComponent from "./AutocompleteComponent.vue";
import MultipleSelection from './MultipleSelection.vue';
import { tools } from "@/mixins/tools";
import i18n from "@/plugins/i18n";
export default {
  name: 'StepParallelFields',
  components: {
    AutocompleteComponent,
    MultipleSelection,
  },
  mixins: [tools],
  data() {
    return {
      isDirty: [],
      isValid: [],
      rowsCounter: 1,
      selectedItems: {},
      valuesByDefault: {},
    };
  },
  props: {
    config: { type: Object },
    currentDataValues: { type: Object },
    dataSource: { type: Object },
    previousStepData: { type: Object},
    submitFormComponent: { type: Boolean },
    secondPreviousData: { type: Object },
  },
  computed: {
    addRowDisabled() {
      return this.valid === false;
    },
    deleteRowDisabled() {
      return this.rowsNumber === 1;
    },
    dirty() {
      return this.isDirty.flat().some(el => el === true);
    },
    itemsCount() {
      return this.currentDataValues && this.outputKey ? Math.max(...Object.values(this.currentDataValues).map(fileValue => fileValue[this.outputKey].length)) : 0;
    },
    outputKey() {
      return this.config?.formAttributeOutput;
    },
    rowsNumber() {
      return this.config.multiRow === true ? this.rowsCounter : 1;
    },
    valid() {
      const flatIsValid = this.isValid.flat();
      return flatIsValid.filter(el => el === true).length === this.config?.components?.length * this.rowsCounter;
    },
    valuesPerColumn() {
      if (!this.config.multiRow || !this.currentDataValues) return {};
      return Object.entries(this.currentDataValues).reduce((acc, [currKey, currValue]) => {
        if (!acc[currKey]) acc[currKey] = [];
        acc[currKey] = [...currValue[this.outputKey].map(el => el.value)];
        return acc;
      }, {})
    },
  },
  methods: {
    consolidateConfig(componentConfig, columnIndex, rowIndex) {
      const config = this.deepCopyFunction(componentConfig);
      if (rowIndex < this.rowsNumber) {
        config.autofocus = false
      }
      if (config.indexedLabel) {
        const label = i18n.t(
          config.indexedLabel, 
          { fileIndex: columnIndex + 1, ...(this.config.multiRow && { keyIndex: rowIndex })}
        );
        config.indexedLabel = label;
      }
      return config;
    },
    deleteRow(rowIdx) {
      if (!this.outputKey) return;
      this.rowsCounter -= 1;
      this.isValid.splice(rowIdx, 1);
      this.isDirty.splice(rowIdx, 1);

      let selectedItems = this.deepCopyFunction(this.selectedItems);
      let isInitialStatus = false;
      Object.entries(selectedItems).forEach(([fileKey, fileValue]) => {
        fileValue[this.outputKey].splice(rowIdx, 1);
        fileValue[this.outputKey]?.map((el, idx) => {
          el.key = idx
          return el;
        });
        selectedItems[fileKey] = fileValue;
        isInitialStatus = this.rowsNumber === 1 && this.rowsNumber > fileValue[this.outputKey].length
      })
      if (isInitialStatus) {
        selectedItems = {};
      }
      this.$set(this, 'selectedItems', selectedItems);
      this.$emit('update', this.selectedItems);
    },
    getCurrentDataValues(formAttr, columnIndex, rowIndex) {
      if (!this.currentDataValues ||!formAttr) return null;
      let dataValue = this.currentDataValues?.[`file_${columnIndex}`]?.[formAttr];
      if (this.config.multiRow) {
        dataValue = dataValue?.find(el => el.key === rowIndex)?.value;
      }
      return { [formAttr]: dataValue }
    },
    // TODO refactor: multiple listeners dirty 
    onDirty(ev, columnIndex, rowIndex) {
      const { dirty } = typeof ev === 'boolean' ? { dirty: ev } : ev;
      if (this.config.multiRow) {
        const isDirty = [...this.isDirty];
        if (!Array.isArray(isDirty[rowIndex])) isDirty[rowIndex] = [];
        isDirty[rowIndex][columnIndex] = dirty;
        this.$set(this, 'isDirty', isDirty);
      } else {
        this.$set(this.isDirty, columnIndex, dirty);
      }
    },
    onIsValid(ev, columnIndex, rowIndex) {
      if (this.config.multiRow) {
        const isValid = [...this.isValid];
        if (!Array.isArray(isValid[rowIndex])) isValid[rowIndex] = [];
        isValid[rowIndex][columnIndex] = ev;
        this.$set(this, 'isValid', isValid);
      } else {
        this.$set(this.isValid, columnIndex, ev);
      }
    },
    onUpdate(ev, targetKey, comp, formAttr, rowIndex) {
      let selectedItems = this.deepCopyFunction(this.selectedItems);
      if (!ev && comp === 'MultipleSelection') {
        selectedItems[targetKey] = ev;
      } else if (!ev) {
        return;
      } else {
        if (!selectedItems[targetKey]) {
          selectedItems[targetKey] = {};
        } 
        // TODO refactor: make the event types robust and consistent
        if (typeof ev === 'string') {
          selectedItems[targetKey][formAttr] = ev;
        } else {
          Object.entries(ev).forEach(([evKey, evValue]) => { 
            if (this.config.multiRow) {
              if (!selectedItems[targetKey]?.[evKey]) {
                selectedItems[targetKey][evKey] = [];
                selectedItems[targetKey][evKey].push({ key: rowIndex, value: evValue });
              } else {
                const toUpdateIdx = selectedItems[targetKey][evKey].findIndex(el => el.key === rowIndex);
                const newValue = { key: rowIndex, value: evValue };
                if (toUpdateIdx > -1) {
                  selectedItems[targetKey][evKey][toUpdateIdx] = newValue;
                } else {
                  selectedItems[targetKey][evKey].push(newValue);
                }
              }
            } else {
              selectedItems[targetKey][evKey] = evValue 
            }
          });
        }
      }
      this.$set(this, 'selectedItems', selectedItems);
      this.$emit('update', this.selectedItems);
    },
    emitValuesByDefault(ev, targetKey, formAttribute) {
      this.valuesByDefault[targetKey] = {[formAttribute]: ev};

      this.$emit('setValuesByDefault', this.valuesByDefault);
    },
  },
  watch: {
    currentDataValues: {
      handler(newValue) {
        if (newValue) {
          let selectedItems = this.deepCopyFunction(this.selectedItems);
          Object.entries(newValue).forEach(([fileKey, fileValue]) => {
            selectedItems[fileKey] = fileValue; 
          })
          this.$set(this, 'selectedItems', selectedItems);
        }
      },
      deep: true,
      immediate: true,

    },
    dirty(newValue) { if (newValue) this.$emit('dirty', newValue); },
    submitFormComponent(v) {
      if (v) {
        this.$emit('isValid', this.valid);
        this.$nextTick(() => {
          this.$emit('isDirty', { dirty: this.dirty });
        })
      }
    },
    valid(newValue) { this.$emit('isValid', newValue); },
  },
  created() {
    this.rowsCounter = this.itemsCount > 0 ? this.itemsCount : 1;
  }
}
</script>

<style lang="scss" scoped>
.formTitle {
  font-size: 16px;
  font-weight: 500;
  color: var(--secondary);
}
</style>