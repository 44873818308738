<template>
  <div
    class="join-schema-wrp"
  >
    <v-btn-toggle
      class="join-schema-btn-group"
      color="primary"
      group
      v-model="selectedJoinType"
    >
      <v-btn
        v-for="(joinImg, idx) in joinSelectorsConfig"
        class="join-schema-btn"
        color="primary"
        :key="`${joinImg.joinType}-${idx}`" 
        outlined
        ref=""
        rounded
        :value="joinImg.joinType"
      >
        <div class="d-flex flex-column align-center">
          <img :src="joinImg.path" />
          <span class="join-schema-btn__description">{{ $t(joinImg.description) }}</span>
        </div>
      </v-btn>
    </v-btn-toggle>
    <span 
      class="d-flex"
      v-if="showAlert"
    >
      <v-alert
        class="mt-5"
        color="error"
        icon="mdi-alert-circle"
        outlined
        type="error"
      >
        {{ $t("joinTypeNotSelected") }}
      </v-alert>
    </span>
  </div>
</template>

<script>
export default {
  name: 'JoinSelectType',
  props: {
    submitFormComponent: { type: Boolean },
    currentDataValues: { type: Object },
  },
  data() {
    return {
      dirty: false,
      joinSelectorsConfig: [
        {
          path: '/img/join/INNER_JOIN.svg',
          joinType: 'INNER',
          description: 'joinDescriptionInner',
        },
        {
          path: '/img/join/OUTER_JOIN.svg',
          joinType: 'OUTER',
          description: 'joinDescriptionOuter',
        }
      ],
      selectedJoinType: null,
      showAlert: false,
      valid: false,
    }
  },
  methods: {
    setStepStatus(joinType) {
      const existsJoinType = !!joinType;
      if (existsJoinType) {
        this.dirty = true;
        if (this.showAlert) this.showAlert = false;
      }
      this.valid = existsJoinType;
    }
  },
  watch: {
    currentDataValues(newValue) {
      this.selectedJoinType = newValue?.['joinType'] || null;
      if (!this.selectedJoinType) this.dirty = false;
    },
    dirty(newValue) {
      if (newValue) this.$emit('dirty', newValue);
    },
    selectedJoinType(newValue) {
      const joinType = newValue ? { 'joinType': newValue } : null;
      this.setStepStatus(joinType);
      this.$emit("update", joinType);
    },
    submitFormComponent(newValue) {
      if (newValue) {
        this.valid = !!this.selectedJoinType;
        if (!this.showAlert) {
          this.showAlert = !this.selectedJoinType;
        } else {
          this.$emit('isValid', this.valid);
        }
      }
    },
    showAlert(newValue) {
      this.$emit('isValid', !newValue);
    },
    valid(newValue) {
      this.$emit('isValid', newValue);
    }
  },
  created() {
    this.selectedJoinType = this.currentDataValues?.['joinType'] || null;
  }
}
</script>

<style lang="scss" scoped>
.join-schema-wrp {
  .join-schema-btn-group {
    background-color: unset;
    &.v-btn-toggle.v-btn-toggle--group > .v-btn {
      background-color: white!important;
      border: 1px solid var(--borderGray);
      border-radius: 10px;
      height: 136px;
      margin: 0px;
      opacity: 1;
      width: 240px;
      letter-spacing: inherit;
      &:first-child {
        margin-right: 20px;
      }
      &.v-btn--active {
        border-color: var(--primary)!important;
      }
      &.v-btn:focus-visible {
        outline: 2px solid #9c40c7;
        outline-offset: 4px;
      }
      .join-schema-btn__description {
        color: var(--darkGray);
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        margin-top: 10px;
      }
    }
  }
}

</style>