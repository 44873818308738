<template>
  <div 
    class="import-file"
    id="app-container"
    @keydown.enter="handleKeydown"
  >
    <template>
      <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
      <div v-else class="container-flex">
      <section>
      <ImportSourceSelector
        :hasTrafisCloudBtn="hasTrafisCloudBtn"
        :wrpClasses="wrpClasses"
        :color="'primary'"
        :selectedSource="selectedImportSource"
        :googleDeveloperKey="googleDeveloperKey"
        :googleClientId="googleClientId"
        :googleAppId="googleAppId"
        @sourceSelected="changedSelectedImportSource"
        @fileSelectedDrive="fileSelectedDrive"
        ref="importSourceSelector"
      />
      <template v-if="alert">
        <v-alert
          outlined
          :type="alert.type"
          :icon="alert.icon"
          :color="alert.color"
          class="mt-5"
        >
          <span v-if="alert.isHtml" v-html="alert.msg"></span>
          <template v-else> {{ alert.msg }}</template>
        </v-alert>
      </template>

          <Import
            v-if="selectedImportSource === 'yourPc'"
            class="importFileArea mt-5"
            :title="$t('importFileFromYourPc')"
            :subtitle="$t('clickOrDragToImport')"
            :icon="'mdi-laptop'"
            @file="fileSelected"
            :color="'primary'"
            id="importComponent"
            tabindex="0"
          />

      <FileToUpload
         v-if="fileCondition"
        :file="selectedFiles[`file_0`]?.selectedFile"
        :wrpClasses="wrpClasses"
        @clearFile="clearFile"  
        class="mt-5"
      />
      
      <CloudFileToUpload  
        v-if="selectedImportSource === 'cloud'"
        :resetForm="resetComponent" 
        :cloudFiles="cloudFiles"
        :currentDataValues="selectedFiles"
        @cloudFile="fileSelected"
        :submitFormComponent="submitFormComponent"
        :maxFiles="this.configInput?.allowedFiles"
    
      />
    </section>
  
    <FilesSelectedCard 
      v-if="showCardFiles"
      @clearFile="clearFile"
      :filesToshow="selectedFiles"
      :notShowError="notShowErrorMsg"
      class="filesCard"
      :config="configInput"
      :submitFormComponent="Boolean(submitFormComponent)"
      :errorFileImport="errorFileImport"
      :importFileDirty="!!selectedImportSource"
      :hasReference="hasReference"
      :selectedImportSource="selectedImportSource"
      @update="selectedFiles = $event"
      @isValid="$emit('isValid', $event)"
      />
    </div>  
    </template>
  </div>
</template>

<script>
import ImportSourceSelector from "@/components/ImportSourceSelector.vue";
import FileToUpload from "@/components/FileToUpload.vue";
import CloudFileToUpload from "@/components/CloudFileToUpload.vue";
import i18n from "@/plugins/i18n";
import { mapState } from "vuex";
import { firebaseTools} from "@/mixins/firebase-tools";
import FilesSelectedCard from "./FilesSelectedCard.vue";

export default {
  name: "ImportFile",
  components: {
    ImportSourceSelector,
    FileToUpload,
    CloudFileToUpload,
    FilesSelectedCard
  },
  mixins: [firebaseTools],
  props: {
    submitFormComponent: { type: Boolean },
    currentDataValues: { type: Object },
    resetComponent: { type: Boolean },
    hasTrafisCloudBtn: {
      type: Boolean,
      default: false,
    },
    config: { type: Object },
    showCardFiles: { type: Boolean, default: false },
    hasReference: { type: Boolean, default: false },
  },
  data: () => ({
    googleAppId: window.PROJECT_ENV.GOOGLE_APP_ID,
    googleClientId: window.PROJECT_ENV.GOOGLE_CLIENT_ID,
    googleDeveloperKey: window.PROJECT_ENV.API_KEY,
    selectedImportSource: undefined,
    selectedFiles:{},
    errorFileImport: undefined,
    loading: false,
    hasErrorExtensionFile: false,
    cloudFiles: [],
    warningFileImport: undefined,
    dirty: false,
    defaultAllowedExtensionFilesConfig: {allowedExtensionFiles : ["csv", "xlsx"]}
  }),
  async created() {
    if (this.hasTrafisCloudBtn) await this.setCloudFiles();
    if (this.currentDataValues) {
      this.selectedFiles = this.currentDataValues;
      const firstFile = Object.values(this.selectedFiles)[0];
      if (firstFile) {
        this.selectedImportSource = firstFile.selectedFrom;
      }
    }
  },
  computed: {
    ...mapState(["actualGroup"]),
    isMultiFile() {
      return this.configInput.allowedFiles !== undefined;
    },
    wrpClasses() {
      return {
        medium: true,
      };
    },
    isSelectedAllFilesAllow(){
      const allowedFiles = this.configInput?.allowedFiles || 1;
      return this.selectedFilesLength === allowedFiles;
    },
    isFormValid() {
      if (this.errorFileImport) return false;
      if (!this.selectedImportSource && !this.isSelectedAllFilesAllow) return false;
      return this.isSelectedAllFilesAllow
    },
    configInput() {
      return { ...this.defaultAllowedExtensionFilesConfig, ...this.config };
    },
    allowedExtensionFilesRegexp() {
      const { allowedExtensionFiles } = this.configInput;
      return new RegExp(`[^s]+(.*?).(${allowedExtensionFiles.join("|")})$`);
    },
    notShowErrorMsg() {
      return Boolean(this.selectedImportSource === 'cloud' && !Object.keys(this.selectedFiles).length);
    },
  
    alert() {
      let alert = {
        type: this.warningFileImport ? 'warning' : 'error',
        icon: this.warningFileImport ? 'mdi-alert' : 'mdi-alert-circle',
        color: this.warningFileImport ? 'warning' : 'error',
        msg: this.warningFileImport || this.errorFileImport,
        isHtml: Boolean(this.warningFileImport) 
      };
      return !alert.msg ? undefined : alert;
    },
    remainingFiles() {
      const alreadySelected = this.selectedFilesLength;
      const allowedFiles = this.configInput?.allowedFiles;
      return allowedFiles - alreadySelected;
    },
    fileCondition (){
      return this.selectedFiles && this.selectedFiles['file_0'] && this.selectedFiles['file_0'].selectedFile && this.selectedImportSource !== 'cloud' && !this.showCardFiles;
    } ,
    selectedFilesLength() {
      return Object.keys(this.selectedFiles).length;
    },
  },
  watch: {
    actualGroup: {
      handler() {
        if (this.hasTrafisCloudBtn) this.setCloudFiles();
      },
      deep: true,
    },
    selectedFiles: {
      handler(val) {
        if (this.hasTrafisCloudBtn) this.checkIfFileIsInStorageAndSetValidation();
        const allowedFiles  = this.configInput?.allowedFiles;
        if ((this.isMultiFile === false && this.selectedFilesLength === 1) ) {
          const { selectedFile, selectedFrom } = val?.['file_0'] || {};
          this.$emit("update",{ selectedFile, selectedFrom })
          this.$emit("isValid", true);
        } else if (this.isMultiFile && this.selectedFilesLength === allowedFiles) {
          const isValid = !this.hasReference || (this.hasReference && Object.values(val).some(file => file.isReference));
          this.$emit("update", { ...val });
          this.$emit("isValid", Boolean(isValid));
        } else {
          this.$emit("update", null);
          this.$emit("isValid", false);
        }
        if (this.selectedFilesLength > 0) this.dirty = true;
      },
      deep: true,
    },
    currentDataValues: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal?.selectedFiles) {
          const firstFile = Object.values(newVal.selectedFiles)[0];
          if (firstFile) {
            this.selectedImportSource = firstFile.selectedFrom;
          }
          this.selectedFiles = newVal.selectedFiles;
        }
      }
    },
    submitFormComponent(val) {
      if (val) {
        if (!this.selectedImportSource && !this.isSelectedAllFilesAllow) {
          this.errorFileImport = i18n.t("youMustSelectAnImportOption");
          this.$emit("isValid", false);
        } else if (this.hasReference) {
          const isValid = !this.hasReference || (this.hasReference && Object.values(this.selectedFiles).some(file => file.isReference));
          this.$emit("isValid", isValid);
        } else if (!this.hasErrorExtensionFile) {
          this.errorFileImport = undefined;
          this.$emit("isValid", true);
        }
      }
    },
   errorFileImport(val) {
      if (val) {
        this.$emit("isValid", false);
      } else {
        this.$emit("isValid", true);
      }
    },
    resetComponent(val) {
      if (val) {
        if (!this.currentDataValues?.selectedFiles) {
          this.selectedImportSource = undefined;
          this.$set(this, 'selectedFiles', {});
        }
        this.errorFileImport = undefined;
        this.warningFileImport = undefined;
        this.clearInnerInput();
        this.dirty = false;
      }
    },
    dirty(val) {
      if (val) this.$emit("dirty", val);
    },
  },
  methods: {
    checkIfFileIsInStorageAndSetValidation() {
      const cloudFilesNames = this.cloudFiles.map(file => file.name);
      const filesNotFromCloud = Object.values(this.selectedFiles).filter(file => file.selectedFrom !== 'cloud');
      const selectedFileNames = filesNotFromCloud.map(fileObject => fileObject.selectedFile ? fileObject.selectedFile.name : "");
      const filesOnCloud = cloudFilesNames.filter(name => selectedFileNames.includes(name));
      if (filesOnCloud && filesOnCloud.length) {
        const { warningMsgCloudShowingFiles } = this.configInput || {};
        const htmlFiles = filesOnCloud.map(file => `<i style='word-break: break-all'">${file}</i>`).join("<br>");

        this.warningFileImport = warningMsgCloudShowingFiles ? i18n.t("warningCloudFileShowingFiles", {
            files: htmlFiles
          }) : i18n.t("warningCloudFile");
      } else {
        this.warningFileImport = undefined;
      }
    },
    async setCloudFiles() {
      const path = `${this.actualGroup.id}/input`;
      this.loading = true;
      await this.getFilesFromStorage(path).then(files => {
        this.cloudFiles = files;
        this.loading = false;
      });
    },
    changedSelectedImportSource(selectedSource) {
      this.dirty = true;
      this.selectedImportSource = selectedSource;
        this.errorFileImport = undefined;
    },
    isValidExtensionFile(name) {
      const valid = this.allowedExtensionFilesRegexp.test(name);
      if (!valid) this.clearInnerInput();
      this.errorFileImport = !valid
        ? i18n.t("extensionErrorFile", {
            element: this.configInput?.allowedExtensionFiles.join(
              ` ${i18n.t("or")} .`
            ),
          })
        : undefined;
      this.hasErrorExtensionFile = !valid;
      return valid;
    },
    //to do refactor
    fileSelected(event) {
      if (!event) {
        const filteredFiles = Object.fromEntries(
            Object.entries(this.selectedFiles)
                .filter(([, value]) => value.selectedFrom !== this.selectedImportSource)
                .map(([, value], index) => [`file_${index}`, value])
        );
        this.$set(this, "selectedFiles", filteredFiles);
        return;
      }
      const { name } = event || {};
      const allowedFiles = this.configInput?.allowedFiles || 1;
      this.errorFileImport = undefined;
      this.warningFileImport = undefined;
      const fileObject = {
        selectedFile: event,
        selectedFrom: this.selectedImportSource
      };

      if (event && this.isValidExtensionFile(name)) {
        const selectedFileNames = Object.values(this.selectedFiles).map(file => file.selectedFile.name);
        const keys = Object.keys(this.selectedFiles);
        if (this.selectedFilesLength >= allowedFiles) {
          this.$set(this, "selectedFiles", { file_0: fileObject });
        } else {
          const nextIndex = keys.length >= 1 ? 1 : 0;
          if (nextIndex === 1 && selectedFileNames.includes(name)) {
            return;
          }
          this.$set(this.selectedFiles, `file_${nextIndex}`, fileObject);
        }
      }
    },
    async fileSelectedDrive({ fileData, oauthToken }) {
      this.loading = true;
      const fetchOptions = {
        headers: { Authorization: `Bearer ${oauthToken}` },
      };
      const { id, name } = fileData;
      const blob = await fetch(
        `https://www.googleapis.com/drive/v3/files/${id}?alt=media`,
        fetchOptions
      ).then((res) => {
        this.loading = false;
        return res.blob();
      });
      const driveFile = new File([blob], name, fileData);
      const allowedFiles  = this.configInput?.allowedFiles;
      const fileObject = {
        selectedFile: driveFile, 
        selectedFrom: this.selectedImportSource
      };
      if (blob && this.isValidExtensionFile(name)) {
        if (allowedFiles >= 0) {
          const selectedFileNames = Object.values(this.selectedFiles).map(file => file.selectedFile.name);
          const keys = Object.keys(this.selectedFiles);

          if (this.selectedFilesLength >= allowedFiles) {
            this.$set(this, "selectedFiles", { file_0: fileObject });
            this.errorFileImport = undefined;
          } else {
            const nextIndex = keys.length >= 1 ? 1 : 0;
            if (nextIndex === 1 && selectedFileNames.includes(name)) {
              return;
            }
            this.$set(this.selectedFiles, `file_${nextIndex}`, fileObject);
          }
        } else if(!allowedFiles) {
          this.$set(this, "selectedFiles", { file_0: fileObject })
          this.$emit(
          "update",
          { selectedFile: driveFile , selectedFrom: this.selectedImportSource }); 
        }
      } else {
        console.log("Archivo no válido o sin blob.");
      }
    },
    clearFile(index) {
      if (index !== undefined && this.selectedFiles[index]) {
        this.$delete(this.selectedFiles, index);
        const updatedFiles = {};
        let newIndex = 0;
        Object.keys(this.selectedFiles).sort().forEach((key) => {
          updatedFiles[`file_${newIndex}`] = this.selectedFiles[key];
          newIndex++;
        });
        this.selectedFiles = updatedFiles;
      } else {
        this.selectedImportSource = undefined;
        this.$set(this, 'selectedFiles', {});
      }
      this.clearInnerInput();
      this.$emit("update", null);
      this.$emit("isValid", this.selectedFilesLength === (this.configInput?.allowedFiles || 1));
    },
    clearInnerInput() {
      const inputFile = document.querySelector(
        ".import-file input[type='file']"
      );
      if (inputFile) inputFile.value = "";
    },
    handleKeydown(event) {
      if (
        event.key === "Enter" &&
        event.target.classList.contains("importFileArea")
      ) {
        const inputFile = document.getElementById("file");
        if (inputFile) {
          inputFile.click();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-progress-circular{
margin: 0 auto;
}
.v-alert {
  font-size: 16px;
}
.container-flex{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline; 
  justify-content: space-between;
  gap: 20px;
  column-gap: 50px;
  width: 100%;  
}
.filesCard {
  align-self: self-start;
  flex-grow: 2;
  max-width: 420px;
}
section{
  max-width: 410px;
}
.importFileArea {
  color: var(--fontColorTerciary);
  min-height: 200px;
  width: 95%;

  ::v-deep {
    .dropArea {
  text-align: center;
      p {
        margin: 0;
      }

      padding: 30px 85px;
    }

    .dropArea i {
      height: 100%;
      color: var(--primary);
    }

    .dragFile {
      font-weight: 400;
    }

    .clickImport {
      font-weight: 500;
    }

    &.importFileArea:focus-visible {
      outline: 2px solid #9c40c7;
      outline-offset: 4px;
      border-radius: 10px;
    }
  }
}
</style>