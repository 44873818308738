<template>
  <td v-if="Array.isArray(itemValue)" class="pt-3 pb-3">
    <ul>
      <li v-if="itemValue.length" class="d-flex align-center flex-wrap">
        <template v-if="showedInChips.includes(itemKey)">
          <v-chip v-if="itemValue[0]" class="mr-3" color="#ddd"> {{ getChipText() }}</v-chip>
          <v-tooltip v-if="itemValue.length > 1" top max-width="200">
            <template v-slot:activator="{ on }">
              <div v-on="on" class="multiple-chips__caption mt-1">
                (+{{ itemValue.length - 1 }} {{ $tc(mapMessages[itemKey], itemValue.length - 1) }})
              </div>
            </template>
            <span class="multiple-chips__tooltip">{{ getTooltipText() }}</span>
          </v-tooltip>
        </template>
        <template v-else>
          <span v-for="(val, index) in itemValue" :key="index">
            <span>
              {{ val }}
              {{ index !== itemValue.length - 1 ? ", " : "" }}
            </span>
          </span>
        </template>
      </li>
    </ul>
  </td>

  <td v-else>
    <template>
      <td v-if="itemKey === 'user'" class="user-mail">{{ itemValue.email }}</td>
      <td v-else-if="itemKey === 'inputFileName'"  >
        <v-chip color="#ddd">
          {{ itemValue }}
          <v-icon v-if="isReferenceFile" color="var(--secondary)" size="18">{{ "mdi-star" }}</v-icon>
        </v-chip>
      </td>

      <td v-else-if="!!itemValue">{{ itemValue }}</td>
    </template>
  </td>
           
  </template>
  
  <script>
  export default {
    name: 'ProcessInfoCell',
    props: {
      itemKey: {
        type: String,
      },
      itemValue: {
        type: [String, Object, Array],
      },
      isReferenceFile: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showedInChips: ["selectedFilterNames", "primaryKeyColumns", "finalColumns", "outputDefinitionNames", "variables"],
        keyedChips: ["variables"],
        mapMessages: {
          "selectedFilterNames": "processHistory.filter",
          "primaryKeyColumns": "processHistory.column",
          "finalColumns": "processHistory.column",
          "outputDefinitionNames": "processHistory.outputDefinitions",
          "variables": "processHistory.variables"
        }
      };
    },
    methods: {
      formatKeyedValues(keyedValue) {
        if (!keyedValue?.key) return;
        return `${keyedValue.key}: ${keyedValue.value}`;
      },
      getChipText() {
        if (this.keyedChips.includes(this.itemKey)) return this.formatKeyedValues(this.itemValue[0]);
        return this.itemValue[0];
      },
      getTooltipText() {
        const values = this.itemValue.slice(1);
        if (this.keyedChips.includes(this.itemKey)) return values.map(val => this.formatKeyedValues(val)).join(', ');
        return values.join(', ');
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  
  .user-mail {
    color: var(--link) !important;
    text-decoration: underline;
  }
  .mdi-star {
    margin-left: 10px;
  }
  .v-chip{
    color: var(--fontColorSecondary);
  }

  .multiple-chips {
    &__caption {
      color: #777777;
      cursor: pointer;
      font-size: 14px;
      font-style: italic;
      margin: .2em;
    }
    &__tooltip {
      word-wrap: break-word;
    }
  }
  </style>