<template>
  <v-breadcrumbs class="px-0 pt-0" :class="$vuetify.breakpoint.smAndDown ? 'pb-4' : 'pb-5'" :items="items" ref="headerBreadCrums">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.index !== items.length  && item.to || '' "
        exact
        :disabled="item.disabled"
      >
         <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
        <span>{{ item.text }}</span>
      </v-breadcrumbs-item>
    </template> 
  </v-breadcrumbs>
</template>


<script>
import i18n from "@/plugins/i18n";
import {  mapState } from "vuex";
export default {
  name: "Breadcrumbs",
  props: ["dataFromView"],
  data: () => ({
    items: [],
  }),
  methods: {
    getItems(){
      const home = {
        icon: "mdi-home",
        disabled: false,
        to: { name: 'Home'}
      };
      if (!this.isHome) {
        this.items = [home];
      } else {
        this.items = [];
      }
      const { matched: pathItems, params } =  this.$router.currentRoute;
      pathItems.forEach((element, index) => {
        if (element.meta?.parent && element.meta?.showParentInBreadcrumb) {
          this.items.push({
            text: i18n.t(element.meta.parent),
            index: index,
            to: `/${element.meta.parent}`,
            disabled: false
          });
        }
        if (element.name && !element.meta?.isLayout)
          this.items.push({
            text: i18n.t(element.name || element.meta.name),
            index: index,
            to:'/'+element.path.split('/').filter(Boolean)[0],
            parentName: element.parent?.name
          });
      });

      const paramToCheck = 'id';
      if (Object.keys(params).includes(paramToCheck) && params[paramToCheck]) {
        this.$set(this.items, this.items.length - 1, {
          text: this.dataFromView,
          index: this.items.length - 1,
          disabled: true
        });
      }
  },
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.getItems();
      },
    },
    dataView(){
      this.getItems()
    }
  },
  computed: {
    ...mapState([
      "dataView",
    ]),
    isHome() {
      return this.$route.name === 'Home'
    },
  },
};
</script>
