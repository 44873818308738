<template>
  <section :key="selectedCase">
    <section class="conditions-title">
      <template v-if="showCases">
        a. {{ $t("conditions") }} {{ $t("case").toLowerCase() }} {{ selectedCase + 1 }}
      </template>
      <template v-else>
        {{ $t("conditions") }}
      </template>
      <v-icon :color="'primary'" class="conditions-header-button" :size="30" tag="button" :disabled="disabledAddCondition" @click="addCondition()">
        mdi-plus-circle
      </v-icon>
    </section>
    <section v-if="!formConditions.length" class="no-conditions">
      {{ $t("noConditionsApplied") }}
    </section>
    <section v-else>
      <v-expansion-panels v-model="openedCondition" accordion>
        <v-expansion-panel
          v-for="(item, i) in formConditions"
          :key="i + (formConditions && formConditions.length)"
          class="pa-0 ma-0"
          :class="{'last-condition mb-6 rounded-0': formConditions.length - 1 === i}"
        >
          <v-expansion-panel-header class="expansion-header">
            <section class="section-chip">
              <v-chip v-if="areValidConditions[i]" :text-color="'var(--fontColor)'" :color="'#ddd'">
                <span class="chip-text">
                  {{ getChipText(formConditions[i]) }}
                </span>
              </v-chip>
              <span
                v-else
                :class="{
                  'error--text': showValidations && !areValidConditions[i],
                }"
              >
                <v-icon v-if="showValidations && !areValidConditions[i]" color="error" size="20"
                  >mdi-alert-circle</v-icon
                >
                {{ $t("condition") }} {{ i + 1 }}
              </span>
            </section>

            <template v-slot:actions>
              <v-icon
                :color="'primary'"
                :size="24"
                tag="button"
                class="expansion-header-icon mr-2"
                @click.stop="deleteCondition(i)"
                :disabled="Array.isArray(formConditions) && formConditions.length < 2"
              >
                mdi-delete
              </v-icon>
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content eager class="pb-0">
            <condition-form
              class="conditionForm"
              :key="selectedCase + '-' + i"
              :globalValues="globalValues"
              :currentDataValues="formConditions[i]"
              :submitFormComponent="getStatusComponent"
              @update="updateConditions($event, i)"
              :resetForm="resetForm"
              @alerts="alerts = $event"
              @isValidForm="isValidFormConditions($event, i)"
              :dataSource="dataSource"
              :getStatus="getStatusComponent"
              @dirty="getDirtyStatus($event)"
              :formAttribute="formAttribute"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </section>
    <section v-if="showCases">
      <div class="conditions-title pb-7 pt-0 assign-value-title">
        b. {{ $t("value") }} {{ $t("case").toLowerCase() }} {{ selectedCase + 1 }}
      </div>
      <AssignValues
        v-bind="$props"
        :currentDataValues="currentValuesAsignValue"
        @update="updateAssignValues($event)"
        @isValidForm="isValidAssignValueError($event)"
        @dirty="getDirtyStatus($event)"
        parentComponent="Conditionals"
        @alerts="alertsFromAssignValues = $event"
      />
    </section>
  </section>
</template>

<script>
import { tools } from "@/mixins/tools";
import i18n from "@/plugins/i18n";
import ConditionForm from "./ConditionForm.vue";
import AssignValues from "./AssignValues.vue";

export default {
  name: "Conditionals",
  mixins: [tools],
  components: {
    ConditionForm,
    AssignValues,
  },
  props: {
    globalValues: { type: Array },
    currentDataValues: { type: Object },
    submitFormComponent: { type: Boolean },
    resetForm: { type: Boolean },
    dataSource: { type: Object },
    showCases: { type: Boolean, default: true },
    formAttribute: { type: String, default: "conditionals" },
    selectedCase: { type: Number, default: 0 },
    getStatus: { type: Boolean },
  },
  data: () => ({
    dirty: false,
    openedCondition: undefined,
    formConditions: [],
    areValidConditions: [],
    assignValue: undefined,
    assignValues: {},
    isValidAssignValue: true,
    showValidations: false,
    alerts: [],
    alertsFromAssignValues: [],
    getStatusComponent: false,
  }),
  computed: {
    disabledAddCondition() {
      return this.formConditions.filter(item => !Object.keys(item).length).length ? true : false;
    },
    currentValues() {
      const { [this.formAttribute]: attr = [] } = this.currentDataValues || [];
      return attr[this.selectedCase];
    },
    currentValuesAsignValue() {
      const { conditionalColumn } = this.currentValues || {};

      if (conditionalColumn) {
        return conditionalColumn;
      } else {
        return null;
      }
    },
    formattedOperatorMap() {
      return {
        NOT_EMPTY: i18n.t("NOT_EMPTY"),
        EMPTY: i18n.t("EMPTY"),
        EQUAL_TO: "=",
        NOT_EQUAL: "!=",
        GREATER_THAN: ">",
        LESS_THAN: "<",
        GREATER_THAN_OR_EQUAL_TO: ">=",
        LESS_THAN_OR_EQUAL_TO: "<=",
        REGULAR_EXPRESSION: "~",
      };
    },
    isValidConditions() {
      return Boolean(this.areValidConditions.length) && this.areValidConditions.every((c) => c === true);
    },
  },
  watch: {
    selectedCase() {
      this.openedCondition = undefined;
      this.formConditions = [];
      this.areValidConditions = [];
    },
    submitFormComponent(val) {
      this.$nextTick(() => {
        if (val) {
          this.getStatusComponent = val;

          if (this.formConditions.length) this.showValidations = true;
          if ((!this.isValidConditions || !this.isValidAssignValue) && !this.alerts.includes('reviewForm')) this.alerts.push("reviewForm");
          this.updatingForm();
        } else {
          this.getStatusComponent = val;
        }
      })
    },
    getStatus(val) {
      this.getStatusComponent = val;
      if (val) {
        this.$nextTick(() => {
          this.$emit("dirty", Boolean(this.dirty));
        });
      }
    },
    dirty(val) {
      this.$emit("dirty", val);
    },
    isValidConditions: {
      immediate: true,
      deep: true,
      handler (val) {
        if (val) this.showValidations = false;
  
        if (!this.showCases) this.$emit("isValidForm", { valid: val });
        else {
          const valid = val && this.isValidAssignValue;
          if (valid) this.alerts = this.alerts.filter((a) => a !== "reviewForm");
          this.$emit("isValidForm", { valid });
        }
      },
    },
    isValidAssignValue(val) {
      if (!this.showCases) this.$emit("isValidForm", { valid: true });
      else {
        const valid = val && this.isValidConditions;
        if (valid) this.alerts = this.alerts.filter((a) => a !== "reviewForm");
        this.$emit("isValidForm", { valid: val && this.isValidConditions });
      }
    },
    alerts(val) {
      this.$emit("alerts", [...this.alertsFromAssignValues, ...val]);
    },
    alertsFromAssignValues(val) {
      this.$emit("alerts", [...val, ...this.alerts]);
    },
    currentValues: {
      immediate: true,
      deep: true,
      handler (val) {
        if (!val || (val && !val.conditions)) {
          const { [this.formAttribute]: attr = [] } = this.currentDataValues || {};
          if (!attr.length || attr.length < 2) {
            this.$set(this.formConditions, 0, {});
          }
          if (this.checkIfMoreThanOneNoConditionCases() && !this.alerts.includes("alertErrorConditionsEmpty")) {
            this.alerts.push("alertErrorConditionsEmpty");
          }
        } else {
          this.formConditions = this.deepCopyFunction(val.conditions);
        }
      }
    },
    "formConditions.length"(val) {
      if (val) {
        this.alerts = this.alerts.filter((a) => a !== "alertInfoNewFormEmpty");
        if (!this.checkIfMoreThanOneNoConditionCases()) {
          this.alerts = this.alerts.filter((a) => a !== "alertErrorConditionsEmpty");
        }
      } else {
        this.alerts.push("alertInfoNewFormEmpty");
      }
    },
  },
  created() {
    const hasFilters = (this.formAttribute === 'filters' && Boolean(this.currentDataValues?.conditions?.length))
    if ((!this.currentValues || (this.currentValues && !this.currentValues.conditions)) && !hasFilters) {
      const { [this.formAttribute]: attr = [] } = this.currentDataValues || {};
      if (!attr.length || attr.length < 2) {
        this.$set(this.formConditions, 0, {});
      }
      if (this.checkIfMoreThanOneNoConditionCases()) {
        this.alerts.push("alertErrorConditionsEmpty");
      }
    } else {
      this.formConditions = this.deepCopyFunction(hasFilters ? (this.currentDataValues.conditions || []) : this.currentValues.conditions);
    }
    if (!this.formConditions.length) this.alerts.push("alertInfoNewFormEmpty");
    this.assignValue = this.currentValues?.conditionalColumn?.columnReference?.value;
    this.assignValues = {
      columnReference: { value: this.assignValue, option: "COLUMN_NAME" },
    };
    this.updatingForm();
  },
  methods: {
    checkIfMoreThanOneNoConditionCases() {
      const { [this.formAttribute]: attr = [] } = this.currentDataValues || {};
      const existNoConditionsCase = attr && attr.length && attr.filter(({ conditions }, index) => index !== this.selectedCase && !conditions).length >= 1
      const selectedCaseHasNoConditions = this.formConditions && !this.formConditions.length
      const hasMoreThanOne = existNoConditionsCase && selectedCaseHasNoConditions
      const hasCasesWithConditionsAndSelectedCaseHasNoConditions = selectedCaseHasNoConditions && !existNoConditionsCase

      if (hasMoreThanOne){
        this.isValidFormConditions({valid: !hasMoreThanOne}, this.selectedCase)
      }
      if (hasCasesWithConditionsAndSelectedCaseHasNoConditions){
        this.isValidFormConditions({valid: true}, this.selectedCase)
      }
      return Boolean(hasMoreThanOne) 
    },
    isValidAssignValueError({valid = false}) {
      this.isValidAssignValue = valid;
    },
    isValidFormConditions(event, index) {
      this.$set(this.areValidConditions, index, Boolean(event?.valid));
    },
    updateConditions(event, conditionIndex) {
     this.$set(this.formConditions, conditionIndex, event || {});

      this.updatingForm();
    },
    updateAssignValues(event) {
      this.assignValues = event;
      this.updatingForm();
    },
    updatingForm() {
      let conditionToSubmit = {};
      if (this.formConditions.length) conditionToSubmit = { conditions: this.formConditions };

      if (this.showCases) {
        conditionToSubmit = {
          ...conditionToSubmit,
          conditionalColumn: this.assignValues,
        };
      }

      const { [this.formAttribute]: attr } = this.currentDataValues || [];

      let formToSubmit = {
        [this.formAttribute]: this.deepCopyFunction(
          attr || []
        ),
      };

      if (Object.keys(conditionToSubmit).length) this.$set(formToSubmit[this.formAttribute], this.selectedCase, conditionToSubmit);
      this.$emit("update", formToSubmit );
    },
    getChipText(data) {
      const { initialColumnName, comparisonOperator, endValueReference } = data;

      if(!initialColumnName || !comparisonOperator)return null
      let chipText = `${this.formatValueIfIsWildCardOption(initialColumnName)} ${this.formattedOperatorMap[comparisonOperator]}`
      
      const hasNoEndValue = ["EMPTY", "NOT_EMPTY"].includes(comparisonOperator);
      if(hasNoEndValue)return chipText
      return `${chipText} ${this.formatValueIfIsWildCardOption(endValueReference) || "--"}`
    },
    addCondition() {
      this.dirty = true;
      this.openedCondition = this.formConditions.length;
      this.formConditions.push({});
    },
    deleteCondition(conditionIndex) {
      this.dirty = true;
      this.$delete(this.areValidConditions, conditionIndex);
      this.$delete(this.formConditions, conditionIndex);
    },
    getDirtyStatus(dirty) {
      if (!this.dirty && dirty) {
        this.dirty = dirty;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conditions-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 17px;
  .conditions-header-button {
    margin: 5px;
  }
}
.assign-value-title {
  padding-top: 25px;
}
.no-conditions {
  padding-top: 20px;
  padding-bottom: 30px;
  color: var(--fontColorTerciary);
}
.expansion-header {
  padding: 20px 0px 10px;
  font-size: 14px;
  color: var(--primary);
  section {
    display: flex;
    align-items: center;
    > span:not(.v-chip) {
      font-weight: 500;
    }
  }
}
.v-expansion-panel--active
  > .v-expansion-panel-header--active
  .v-expansion-panel-header__icon
  .v-icon.expansion-header-icon {
  transform: none;
}

.v-expansion-panel-content {
  ::v-deep {
    .v-expansion-panel-content__wrap {
      padding-bottom: 20px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.chip-text { 
  overflow: hidden;
  text-overflow: ellipsis;
}
.section-chip {
  width: calc(100% - 56px);
}
.last-condition {
  border-bottom: 1px solid #CCCCCC;
}
</style>
