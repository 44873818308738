<template>
  <div>
    <div v-if="switchTitle" class="mb-4 titles">
      {{ switchTitle }}
    </div>
    <SwitchForm :resetForm="resetComponent" @dirty="dirty = $event" :config="switchConfig" @update="updateData" :currentDataValues="currentDataValues"></SwitchForm>
    <div v-if="multipleSelectionTitle" class="mb-4 titles">
      {{ multipleSelectionTitle }}
    </div>
    <MultipleSelection v-bind="$props" @dirty="dirty = $event" @setValuesByDefault="setValuesByDefault" @isValid="setIsValid" @update="updateData($event, multipleSelectionConfig.inputAttr)" :config="multipleSelectionConfig"></MultipleSelection>
  </div>
</template>

<script>
import MultipleSelection from "./MultipleSelection.vue";
import SwitchForm from "./SwitchForm.vue";

export default {
  name: "SwitchAndMultipleSelection",
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    currentDataValues: { type: Object },
    submitFormComponent: { type: Boolean },
    resetComponent: { type: Boolean },
    previousStepData: { type: Object },
    secondPreviousData: { type: Object },
  },
  components: {
    MultipleSelection,
    SwitchForm
  },
  data: () => ({
    dataToEmit: undefined,
    dirty: false,
  }),
  methods: {
    updateData(event, attr = undefined) {
      let data = event;
      if (!event && attr) data = {[attr]: event}
      this.dataToEmit = {...this.dataToEmit, ...data};
      this.$emit("update", this.dataToEmit);
    },
    setValuesByDefault(event) {
      this.$emit('setValuesByDefault', event)
    },
    setIsValid(event) {
      this.$emit("isValid", event);
    }
  },
  created() {
    if (this.currentDataValues) this.dataToEmit = this.currentDataValues;
  },
  computed: {
    switchTitle() {
      const { title } = this.config?.switch || {};
      return title;
    },
    multipleSelectionTitle() {
      const { title } = this.config?.multipleSelection || {};
      return title;
    },
    switchConfig() {
      const config = this.config?.switch || {};
      if (config) config.labelStyles = {fontSize: '14px'};
      return config;
    },
    multipleSelectionConfig() {
      return this.config?.multipleSelection || {};
    }
  },
  watch: {
    dirty(val) {
      this.$emit("dirty", val);
    },
    resetComponent(val) {
      if (val) {
        this.dirty = false;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.titles {
  font-weight: 500;
  font-size: 14px;
  color: #117E8A;
}
</style>
