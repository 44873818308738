<template>
  <v-card class="files-container">
    <h3 class="files-container__title">{{ dynamicTitle }}</h3>
    <v-alert 
      v-if="validationMessage && !errorFileImport && !notShowError"
      color="error"
      icon="mdi-alert-circle"
      outlined
      type="error"
    >
      {{ validationMessage }}
    </v-alert>
    <section class="files-container__list" v-if="Object.keys(selectedFiles).length > 0">
      <div v-for="(file,key) in selectedFiles" :key="key" ref="fileToUploadContainer">
        <FileToUpload :fileToUploadContainerWidth="fileToUploadContainerWidth" :file="file.selectedFile" @clearFile="clearFile(key)" :showSize="showSize" :index="key"/>
        <v-btn
          v-if="hasReference"
          icon
          :color="file.isReference ? 'var(--secondary)' : 'var(--custom-grey)'"
          @click="selectReference(key)"
          :index="key"
        >
          <v-icon>{{ file.isReference ? "mdi-star" : "mdi-star-outline" }}</v-icon>
        </v-btn>
      </div>
    </section>
    <div v-else>
      <span class="files-container__no-data">{{ noDataMessages }}</span>
    </div>
  </v-card>
</template>

<script>
import FileToUpload from "@/components/FileToUpload.vue";

export default {
  name: "FilesSelectedCard",
  components: {
    FileToUpload,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    selectedImportSource: {
      type: String,
    },
    filesToshow: {
      type: Object,
      required: true, 
    },
    notShowError: {
      type: Boolean,
      default: false
    },
    submitFormComponent: { type: Boolean },
    errorFileImport: { type: String },
    importFileDirty: { type: Boolean },
    hasReference: { type: Boolean },
  },
  data: () => ({
    validationMessage: null,
    showAlert: false,
    selectedFiles: [],
    fileToUploadContainerWidth: undefined,
  }),
  computed: {
    configInput() {
      return this.config ;
    },
    showSize() {
      return this.configInput.showSize;
    },
    dynamicTitle() {
      return this.configInput.processType === "join"
        ? this.$t("multipleFilesSelected")
        : this.$t("singleFileSelected");   
    },
    noDataMessages(){
      return this.configInput.processType === "join"
        ? this.$tc("noDataMessages",1)
        : this.$tc("noDataMessages",0);  
    }
  },
  watch: {
    selectedImportSource() {
      this.setFileToUploadContainerWidth();
    },
    submitFormComponent(newValue) {
      if (newValue) {
        this.validateFiles();
      }
    },
    selectedFiles: {
      handler(newFiles) {
        if (Object.keys(newFiles).length === 0) {
          this.$emit('isValid', false);
        }
        this.setFileToUploadContainerWidth();
      },
      deep: true,
      immediate: true,
    },
    filesToshow: {
      handler(val) {
        this.validationMessage = null;
        Object.keys(val).forEach(key => {
          if (this.configInput.processType === "join" && !Object.hasOwnProperty.call(val[key],'isReference')) {
            val[key].isReference = false; 
          }
        });
        this.$set(this, 'selectedFiles', { ...val })
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setFileToUploadContainerWidth() {
      this.$nextTick(() => {
          this.fileToUploadContainerWidth = this.$refs?.fileToUploadContainer?.length && this.$refs.fileToUploadContainer[0].clientWidth;
        });
    },
    validateFiles() {
      const fileCount = Object.keys(this.selectedFiles).length;
      const { allowedFiles, processType } = this.config;
      const referenceExists = Object.values(this.selectedFiles).some(file => file.isReference === true);

      let validationMessage = null;
    
      if (processType === "join") {
        if (fileCount < allowedFiles) {
          validationMessage = this.$t("errorJoinFileSelection"); 
        } else if (!referenceExists && this.hasReference) {
          validationMessage = this.$t("selectReferenceFile"); 
        }
      } else if (processType === "validate" || processType === "transform") {
        if (fileCount < allowedFiles) {
          validationMessage = processType === "validate"
            ? this.$t("errorValidationFileSelection")
            : this.$t("errorTransformFileSelection");
        }
      }
      this.validationMessage = validationMessage;

      this.$emit('isValid', Boolean(!validationMessage));
    },
    clearFile(index) {
      this.$emit("clearFile", index); 
    },
    selectReference(index) {
      const selectedFile = this.selectedFiles[index];
      const isReference = selectedFile?.isReference;


      this.$set(this.selectedFiles, index, { 
        ...selectedFile, 
        isReference: !isReference 
      });

      if (!isReference) {
        Object.keys(this.selectedFiles).forEach((key) => {
          if (key !== index.toString()) {

            this.$set(this.selectedFiles, key, { 
              ...this.selectedFiles[key], 
              isReference: false 
            });
          }
        });
      }
      this.$emit("update", { ...this.selectedFiles });
    },
  }
};
</script>
<style scoped lang="scss">
.files-container {
  width: 338px;
  padding: 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  &__title {
    color: #117e8a;
    font-weight: 500;
    font-size: 14px;
  }
  &__list {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
  & div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    justify-content: space-between;
  }
  &__no-data {
    color: var(--lightGray);
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }

  .v-alert {
    margin-top: 0px !important;
    padding: 16px;
    .v-alert__wrapper {
      display: flex;
      gap: 10px;
    }
  }
  .v-btn--outlined {
    color: var(--darkGray);
  }
  .v-btn .mdi-star {
    color: #117E8A !important; 
  }

  .v-btn .mdi-star-outline {
    color: var(--custom-grey) !important; 
  }
  .v-icon {
    font-size: 24px;
  }
}
</style>
