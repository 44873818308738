<template>
  <div>
    <template v-if="step.useFileToUploadForResume && currentDataValues">
      <v-row>
        <v-col 
          v-for="(file, idx) in currentDataValues"
          :key="`${file.selectedFile.name}-${idx}`"
          class="pt-6"
        >
          <v-badge
            top
            color="var(--secondary)"
            icon="mdi-star"
            offset-x="10"
            offset-y="10"
            :value="Boolean(file?.isReference)"
            class="file-badge"
            :class="{'tooltip-badge': file?.isReference}"
          >
            <FileToUpload
              class="resume-file mb-1"
              :file="file.selectedFile"
              :show-size="false"
              :removable="false"
              :wrpClasses="{ medium: true }"
              @clearFile="clearFile(i)"
            />
          </v-badge>
          <v-tooltip
            activator=".tooltip-badge"
            attach=".resume-file"
            allow-overflow
            content-class="file-wrapper__tooltip"
            offset-overflow
          >
            <span>{{ $t('isReferenceFile') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row>
        <v-col v-if="['validation', 'transformation'].includes(processType) || step.component === 'JoinSelectType'">
          <StepsResume 
            :items="step.resume" 
            :currentValues="currentDataValues" 
          />
        </v-col>
        <template v-else>
          <v-col v-for="(_, idx) in step.config.components" :key="idx">
            <StepsResume 
              :items="step.resume" 
              :currentValues="currentDataValues[`file_${idx}`] || currentDataValues"
              :resumeIndex="idx"
            />
          </v-col>
        </template>
      </v-row>
    </template>
  </div>
</template>

<script>
import FileToUpload from '@/components/FileToUpload.vue';
import StepsResume from './StepsResume.vue';

export default {
  name: 'ResumeExecution',
  components: {
    FileToUpload,
    StepsResume
  },
  props: {
    currentDataValues: {
      type: [Array, Object],
      required: true
    },
    step: {
      type: Object,
      required: true
    },
    processType: {
      type: String,
      required: true
    }
  },
}
</script>

<style scoped lang="scss">
.file-badge.v-badge.v-badge--icon {
  ::v-deep .v-badge__badge {
    background-color: var(--primary);
    .v-icon {
      font-size: 12px;
      color: var(--white);
    }
  }
}
.file-wrapper__tooltip{
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
}
</style>