<template>
  <v-dialog
    :key="'stepper-dialog'"
    :value="itemToAddEditStepper"
    :width="900"
    v-click-outside="cancel"
    content-class="dialog containerStepper"
    scrollable
    persistent
  >
    <v-card v-if="itemToAddEditStepper && steps?.length">
      <v-card-title
        v-if="stepperConfig?.title"
        ref="title"
      >
        {{ stepperConfig.title }}
      </v-card-title>
      <v-card-text
        ref="content"
        class="text-content d-flex"
      >
        <div
          class="mr-1 left-stepper"
          min-height="380"
          ref="leftStepper"
        >
          <v-stepper
            v-model="currentStep"
            vertical
            flat
          >
            <div
              v-for="(step, i) in steps"
              :key="i"
              class="step-item"
              :ref="'step' + i"
            >
              <v-stepper-step
                :complete="complete(i)"
                :step="i + 1"
                @click.stop.prevent="selectStep(i + 1)"
              >
                <div>
                  <CasesList
                    v-if="currentStep === (i + 1) && !!step.showCases && componentToShow === 'Conditionals'"
                    :title="step | formatTitle(i, currentDataValues)"
                    :selectedCase="selectedCase"
                    :currentDataValues="currentDataValues[currentStep - 1]" 
                    :formAttribute="step.formAttribute"
                    :isValidForm="Boolean(isValidForm)"
                    :submitFormComponent="submitFormComponent"
                    @changeCase="changeCase"
                    @update="updateComponentForm"
                    @dirty="onDirty"
                  />
                  <span v-else>{{ step | formatTitle(i, currentDataValues) }}</span>
                </div>
              </v-stepper-step>
              <v-stepper-content
                :complete="complete(i)"
                :step="i + 1"
                :class="{'step-resume': step.resume }"
              >
                <steps-resume v-if="currentStep > (i + 1)" :items="step.resume" :currentValues="currentDataValues[i]"/>
              </v-stepper-content>
            </div>
          </v-stepper>
        </div>
        <v-divider vertical></v-divider>
        <div class="ml-1 right-stepper">
          <v-stepper
            :v-model="currentStep"
            vertical
            flat
          >
            <v-stepper-step
              :complete="false"
              :step="currentStep"
            >
              {{ steps[currentStep - 1] | formatTitle(currentStep - 1, currentDataValues) }}
            </v-stepper-step>
            <v-stepper-content :step="currentStep" class="ml-4">
              <v-alert
                v-for="alert in defaultAlerts"
                :key="alert.text"
                outlined
                :type="alert.type"
                :icon="alert.icon"
                class="stepper-alert"
              >
                {{ $t(alert.text) }}
              </v-alert>
              <v-alert
                v-for="alert in alerts"
                :key="alert"
                outlined
                :type="configAlerts[alert].type"
                :icon="configAlerts[alert].icon"
                class="stepper-alert"
              >
                {{ $t(configAlerts[alert].text) }}
              </v-alert>
              <ExpansibleAlert
                v-for="alert in
                alertsFromChild"
                :key="'2' + alert"
                class="stepper-alert"
                :alert="configAlerts[alert]"
                :alertAttr="stepperConfig?.alertAttr"
              />
              <component
                :key="currentStep"
                :is="componentToShow"
                :globalValues="itemToAddEditStepper.globalValues"
                :currentDataValues="currentDataValues[currentStep - 1]"
                :dataSource="itemToAddEditStepper.dataSource"
                :submitFormComponent="submitFormComponent"
                :getStatus="getStatus"
                :config="steps[currentStep - 1].config"
                :showCases="steps[currentStep - 1].showCases"
                :selectedCase="selectedCase"
                :formAttribute="steps[currentStep - 1].formAttribute"
                @update="updateComponentForm"
                @isValidForm="onIsValidForm"
                @dirty="onDirty"
                @alerts="alertsFromChild = $event"
              />
            </v-stepper-content>
          </v-stepper>
        </div>
      </v-card-text>
      <v-card-actions
        v-if="itemToAddEditStepper"
        ref="actions"
      >
        <slot name="actions">
          <v-btn
            rounded
            outlined
            v-if="stepperConfig.secondaryButton && currentStep === 1"
            @click="cancel('buttonCancel')"
          >
            <v-icon left>{{ stepperConfig.secondaryButton.icon }}</v-icon>
            {{ stepperConfig.secondaryButton.text }}
          </v-btn>
          <v-btn
            rounded
            outlined
            v-else
            @click="selectStep(currentStep - 1)"
          >
            <v-icon left>mdi-chevron-left</v-icon>
            {{ $t("back") }}
          </v-btn>

          <v-btn
            @click="currentStep === steps.length ? onSubmit() : selectStep(currentStep + 1)"
            color="primary"
            rounded
            depressed
            v-if="stepperConfig.primaryButton"
          >
            <template v-if="currentStep === steps.length">
              <v-icon left>{{ stepperConfig.primaryButton.icon }}</v-icon>
              {{ stepperConfig.primaryButton.text }}
            </template>
            <template v-else>
              {{ traduceItem("continue") }}
              <v-icon right>mdi-chevron-right</v-icon>
            </template>
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import WithOrWithoutConditions from "../stepperComponents/WithOrWhitoutConditions.vue"
import { tools } from "@/mixins/tools";
import AssignValues from "../stepperComponents/AssignValues";
import InputText from "../stepperComponents/InputText";
import DictionaryKeyValuesForm from "../stepperComponents/DictionaryKeyValuesForm";
import StepsResume from "../stepperComponents/StepsResume";
import Conditionals from "../stepperComponents/Conditionals";
import CasesList from "../stepperComponents/CasesList";
import DefineColumn from "../stepperComponents/DefineColumn.vue";
import ExpansibleAlert from "./ExpansibleAlert.vue";

export default {
  name: "StepperAddEditModal",
  mixins: [tools],
  components: {
    AssignValues,
    WithOrWithoutConditions,
    InputText,
    DictionaryKeyValuesForm,
    StepsResume,
    Conditionals,
    CasesList,
    DefineColumn,
    ExpansibleAlert
  },
  data: () => ({
    currentDataValues: [],
    defaultAlerts: [],
    isValidForm: false,
    submitFormComponent: false,
    dirty: false,
    resetComponent: false,
    getStatus: false,
    currentStep: 1,
    selectedCase: 0,
    alertsFromChild: [],
    alerts: [],
    configAlerts: {
      alertEditDictionary: {
        type: 'warning',
        icon: 'mdi-alert',
        text: 'alertEditDictionary',
      },
      reviewForm: {
        type: 'error',
        icon: 'mdi-alert-circle',
        text: 'reviewValidationsAttr',
      },
      alertInfoNewFormEmpty: {
        type: 'info',
        icon: 'mdi-information',
        text: 'alertInfoConditionsEmpty',
      },
      alertErrorConditionsEmpty: {
        type: 'error',
        icon: 'mdi-alert-circle',
        text: 'alertErrorConditionsEmpty',
      },
      alertNoChanges: {
        type: 'error',
        icon: 'mdi-alert-circle',
        text: 'alertNoChanges',
      },
      mathOpInfo: {
        type: 'info',
        icon: 'mdi-information',
        text: 'reviewMathOpInfo',
        expansibleInfo: [
          "reviewMathOpInfoStep1",
          "reviewMathOpInfoStep2",
          "reviewMathOpInfoStep3",
          "reviewMathOpInfoStep4",
        ],
        expansibleExample: "reviewMathOpInfoExample",
      },
    }
  }),
  computed: {
    ...mapState(["itemToAddEditStepper"]),
    steps() {
      return this.itemToAddEditStepper?.steps || [];
    },
    stepperConfig() {
      const { config } = this.itemToAddEditStepper || {};
      return config
    },
    componentToShow() {
      const stepIndex = this.currentStep - 1;
      const { dependentOnPreviousStep, dependentComponent, component } = this.steps[stepIndex] || {};
      if (dependentOnPreviousStep) {
        const indexPreviousStep = stepIndex - 1;
        return dependentComponent[this.currentDataValues[indexPreviousStep]?.withOrWithoutConditions || 'withOutConditions'];
      }

      return component;
    },
    defaultAlertsConfig() {
      const {defaultAlerts} = this.steps[this.currentStep - 1] || [];
      return defaultAlerts;
    }
  },
  filters: {
    formatTitle: (step, stepIndex, value) => {
      const { dependentOnPreviousStep, dependentTitle, title } = step || {};
      if (dependentOnPreviousStep) {
        const indexPreviousStep = stepIndex - 1;
        return dependentTitle[value[indexPreviousStep]?.withOrWithoutConditions] ?? title;
      }

      return title;
    }
  },
  methods: {
    changeCase(event) {
      if (this.selectedCase === event) this.submitFormComponent = true;
      this.selectedCase = event
    },
    selectStep(targetStep) {
      if (targetStep <= this.currentStep) {
        this.alertsFromChild = [];
        return (this.currentStep = targetStep);
      }
      else this.submitFormComponent = true;
      this.$nextTick(() => {
        if (targetStep === (this.currentStep + 1) && this.isValidForm) this.currentStep = targetStep;
      });
    },
    checkAlerts(item, alert) {
      const { condition, persistent } = alert;
      if (persistent && !this.defaultAlerts.includes(alert)) {
        this.defaultAlerts.push(alert);
      }
      if (!item) return;
      if (Array.isArray(item)) {
        item.forEach(el =>  {
          if (el && typeof el === 'object') this.checkAlerts(el, alert)
        });
      }
      else {
        Object.keys(item).forEach((key) => {
          if (eval(condition) && !this.defaultAlerts.includes(alert)) {
            this.defaultAlerts.push(alert);
          }
          if (item[key] && typeof item[key] === 'object') {
            this.checkAlerts(item[key], alert);
          }
        });
      }
    },
    updateComponentForm(e) {
      this.defaultAlerts = [];
      if (this.defaultAlertsConfig) this.defaultAlertsConfig.forEach(alert =>  this.checkAlerts(e, alert));

      this.$set(this.currentDataValues, this.currentStep - 1, e );
      if (this.alerts.some(alert => alert === 'alertNoChanges')) {
        this.$delete(this.alerts, this.alerts.indexOf('alertNoChanges'));
      }
      this.submitFormComponent = false;
    },
    onDirty(e) {
      if (!this.dirty) this.dirty = e;
      if (e && this.alerts.some(alert => alert === 'alertNoChanges')) this.$delete(this.alerts, this.alerts.indexOf('alertNoChanges'));
    },
    onIsValidForm({valid}) {
      this.isValidForm = valid;
    },
    complete(i) {
      return this.currentStep > (i + 1);
    },
    cancel(e) {
      if (
        !(
          (e.target && e.target.classList.contains("v-overlay__scrim")) ||
          e === "buttonCancel"
        ) ||
        !this.itemToAddEditStepper
      )
        return;
      else {
        this.getStatus = true;
        this.$nextTick(() => {
          this.formSubmitted();
        });
      }
    },
    formSubmitted() {
      this.executeAction({ dirty: this.dirty, action: "cancel" });
      this.getStatus = false;
    },
    onSubmit() {
      this.submitFormComponent = true;
      let dataToSubmit = null;

      this.$nextTick(() => {
        this.submitFormComponent = false;
        const { collectionAttr } = this.stepperConfig || {};
        const { action, section, data, addPositionDynamicByDefault, indexesToUpdate, currentDataValues} = this.itemToAddEditStepper;

        this.$nextTick(() => {
          if (!this.isValidForm || !this.dirty || !this.currentDataValues?.length) {
            if (!this.dirty && !this.alerts.some(alert => alert === 'alertNoChanges')) {
              this.alerts.push('alertNoChanges');
            }
            return;
          }
          if (collectionAttr !== 'filters' && collectionAttr !== 'dictionaries') {
            /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
            dataToSubmit = this.currentDataValues.reduce((result, {withOrWithoutConditions, ...stepValue} = {}) => ({
              ...result,
              ...stepValue
            }), {});
          } else if(collectionAttr === 'dictionaries') {
            dataToSubmit = this.constructResultDictionary(this.currentDataValues, currentDataValues);
          }
          else {
            dataToSubmit = this.currentDataValues.reduce((result, { filters, ...stepValue} = {}) => {
              const { name } = result;
              let stepValueTosubmit = { ...stepValue};
              if (name) {
                stepValueTosubmit = {...filters};
                stepValueTosubmit = {...stepValueTosubmit[this.selectedCase], name}
              }
              return ({
              ...stepValueTosubmit
            })}, {});
          }

          if (this.stepperConfig.addAttributesToSubmit) {
            dataToSubmit = this.setAttributesToSubmit(this.stepperConfig.addAttributesToSubmit, dataToSubmit);
          }
          if (addPositionDynamicByDefault) dataToSubmit = this.addPositionToValues(dataToSubmit);
          if (indexesToUpdate) dataToSubmit = this.transformBeforeSendForm(dataToSubmit, indexesToUpdate);
          this.executeAction({ values: { ...dataToSubmit }, action, section, data });
        })
      }); 
    },
    constructResultDictionary(currentDataValues, oldValues) {
      const result = { name: undefined, dictionaryValues: [{ row: [] }] };
      currentDataValues.forEach(({ name, dictionaryKeys, dictionaryValues }) => {
        if (name) {
          result.name = name;
        } else if (dictionaryKeys) {
          result.dictionaryValues[0].row.push(...dictionaryKeys.map((key) => {
            return { uuid: key.uuid, cellIsKey: true, entry: { key: key.value, value: "" } }
          }));
        } else {
          result.dictionaryValues[0].row.push(...dictionaryValues.map((key) => {
            return { uuid: key.uuid, cellIsKey: false, entry: { key: key.value, value: "" } }
          }));
        }
      });
      if (oldValues) {
        const firstRow = this.deepCopyFunction(result.dictionaryValues[0]);
        oldValues.data.forEach((value, key) => {
          if (!result.dictionaryValues[key]) {
            result.dictionaryValues.push(this.deepCopyFunction(firstRow));
          }
          result.dictionaryValues[key].row.forEach((row) => {
            const oldValue = value.find(val => val.uuid === row.uuid);
            if (oldValue) {
              row.entry.value = oldValue.value;
            }
            delete row.uuid;
          });

        });
      }
      return result;
    },
    addPositionToValues(dataToSubmit) {
      const { currentDataValues, globalValues = []} = this.itemToAddEditStepper;
      const { position = undefined } = currentDataValues || {}

      if (!isNaN(position)) return { position, ...dataToSubmit };
      else return { position: globalValues.length, ...dataToSubmit}
    },
    setAttributesToSubmit(attrs, dataToSubmit) {
      const { currentDataValues } = this.itemToAddEditStepper;
      attrs.forEach(attr => {
        if (currentDataValues[attr] || currentDataValues[attr] === 0) dataToSubmit[attr] = currentDataValues[attr]
      })
      return dataToSubmit;
    },
    transformBeforeSendForm(dataToSubmit, indexesToUpdate) {
      const { nestedAttr } = this.stepperConfig || {};
      const { rowIndex } = indexesToUpdate || {};
      if (nestedAttr) {
        const { globalValues } = this.deepCopyFunction(this.itemToAddEditStepper) || {};
        const indexToUpdate = globalValues[rowIndex].columnReferences.findIndex(({ position }) => position === dataToSubmit.position);
        this.$set(globalValues[rowIndex].columnReferences, indexToUpdate, dataToSubmit);
        return { [nestedAttr]: globalValues };
      }
      return dataToSubmit
    },
    handleKeydown(ev) {
      if (ev.key === 'Escape') this.cancel('buttonCancel');
    }
  },
  watch: {
    itemToAddEditStepper(v) {
      if (!v) {
        this.currentStep = 1;
        this.isValidForm = false;
        this.submitFormComponent = false;
        this.dirty = false;
        this.resetComponent = false;
        this.getStatus = false;
        this.currentDataValues = [];
        this.selectedCase = 0;
        this.alertsFromChild = [];
        this.alerts = [];
        window.removeEventListener('keydown', this.handleKeydown)
        return;
      }
      window.addEventListener('keydown', this.handleKeydown)
      this.steps.forEach((_, index) => {
        this.$set(this.currentDataValues, index, v?.currentDataValues ? JSON.parse(JSON.stringify(v?.currentDataValues)) : null);
      });  
    },
    dirty(val) {
      if (val && this.alerts.length) this.alerts = this.alerts.filter(alert => alert !== 'alertNoChanges');
    },
    defaultAlertsConfig(val) {
      if (val && val.length) {
        val.forEach(alert =>  this.checkAlerts(this.currentDataValues[this.currentStep - 1], alert));
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content {
  ::v-deep {
    .containerStepper {
      border-radius: 20px;
      min-height: 95vh;
    }
  }

  .v-card__title {
    font-weight: 400;
    padding: 20px;
  }

  .v-card__actions {
    padding: 20px;
  }
}

.left-stepper, .right-stepper {
  ::v-deep {
    .v-stepper__step {
      .v-stepper__label {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

.left-stepper {
  width: 45%;
  .v-stepper__step {
    cursor: pointer;
    padding-left: 20px;
    align-items: baseline;
  }

  .step-item {
    &:not(:last-child) {
      ::v-deep {
        .v-stepper__content {
          margin-left: 10px !important;
          border-left: 1px solid var(--borderGray) !important;
          min-height: 40px;
        }
      }
    }
    ::v-deep {
      .v-stepper__step {
        cursor: pointer;
        .v-stepper__label {
          font-size: 16px;
          font-weight: 400;
        }
      }
      .v-stepper__content[complete].step-resume {
        .v-stepper__wrapper {
          height: auto !important;
        }
      }
    }
  }
}

.right-stepper {
  overflow: auto;
  width: 54%;
  .v-stepper__step {
    ::v-deep .v-stepper__label {
      font-weight: 500;
    }
  }

  ::v-deep {
    ::-webkit-scrollbar {
      height: 7px;
    }

    ::-webkit-scrollbar-track {
      background-color: #f1f2f5;
      transition: background-color 0.3s ease-out;
    }

    ::-webkit-scrollbar {
      width: 5px;
      background-color: #f1f2f5;
      transition: background-color 0.3s ease-out;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 120px;
      background-color: #a2a2a2;
      transition: background-color 0.3s ease-out;
    }
    .v-stepper__wrapper {
      height: auto;
      padding-bottom: 25px;
    }
  }
}

.v-stepper--vertical .v-stepper__step {
  padding: 5px 16px 5px 0px;
}

.right-stepper {
  padding-right: 6px;
  .v-stepper {
    // child width  = 100% - parent right-padding
    width: calc(100% - 6px);
    overflow: visible;
  }
  .v-stepper--vertical .v-stepper__step {
    padding: 5px 16px 5px 16px;
  }
}

.v-alert.v-sheet.stepper-alert, .expansible-alert-wrapper.stepper-alert {
  margin-top: 20px;
  margin-bottom: 20px;
  & + .stepper-alert {
    margin-bottom: 0px;
    &.stepper-alert:last-of-type {
      margin-bottom: 20px;
    }
  }
}

.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin: 0;
  padding: 0;
}

.contenedor {
  width: 100%;
}

::v-deep {
  .formContainer.assignValuesForm  > .v-form > .row {
    margin-top: 0px;
  }
  .v-card__text.text-content {
    padding: 0 14px 20px 20px;
    /* heigth - header - footer */
    height: calc(95vh - 72px - 76px);
  }

  .v-stepper__wrapper {
    overflow: visible;
  }
  .v-stepper__step {
    &.v-stepper__step--active .v-stepper__label,
    &.v-stepper__step--complete .v-stepper__label {
      color: var(--fontColor);
    }

    &.v-stepper__step--inactive:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) {
      .v-stepper__step__step {
        background-color: var(--lightGray);
      }
      .v-stepper__label {
        color: var(--fontColorTerciary);
      }
    }
  }
  div.v-card__text.text-content.d-flex .v-stepper__step--active .v-stepper__label {
    font-weight: 500;
  }
}
</style>
