import { render, staticRenderFns } from "./SwitchForm.vue?vue&type=template&id=08a161ab&scoped=true"
import script from "./SwitchForm.vue?vue&type=script&lang=js"
export * from "./SwitchForm.vue?vue&type=script&lang=js"
import style0 from "./SwitchForm.vue?vue&type=style&index=0&id=08a161ab&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a161ab",
  null
  
)

export default component.exports