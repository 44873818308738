<template>
  <v-card class="component-container">
    <v-card-title class="card-title">{{ $t('trafisCloud') }}</v-card-title>
    <v-autocomplete
      class="autocomplete"
      ref="cloudFileInput"
      return-object
      item-text="name"
      v-model="selectedCloudFiles"
      :label="fieldSelectCloudFile.label"
      :items="cloudFiles"
      :chips="true"
      clearable
      multiple
      @input="handleFileSelection"
      :rules="[...fieldSelectCloudFile.validators]"
      :no-data-text="$t('noDataSelects.cloud')"
    >
      <template v-slot:selection="{ index, item }">
        <div class="d-flex align-center autocomplete__chip-selection">
          <v-chip class="mr-2" v-if="index < 1">
            <v-tooltip bottom :disabled="!isTooltipActivated(`${item.name}ChipItem`)">
              <template v-slot:activator="{ on }">
                <div 
                  class="text-truncate" 
                  v-on="on" 
                  @mouseover="setTooltipActivated($event, `${item.name}ChipItem`)"
                >
                  {{ item.name }}
                </div>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </v-chip>
          <span v-if="index === 1" class="autocomplete__multifiles-caption">
            (+{{ selectedCloudFiles.length - 1 }} {{ $t('file') }})
          </span>
        </div>
      </template>
      <template v-slot:item="{ attrs, item }">
        <v-list-item-content>
          <v-list-item-title class="d-flex align-center itemTitle">
            <v-checkbox
              :ripple="false"
              :input-value="selectedCloudFiles.includes(item)"
              class="mr-2"
            ></v-checkbox>
            <v-tooltip bottom :disabled="!isTooltipActivated(`${attrs.id}ListItem`)">
              <template v-slot:activator="{ on }">
                <div 
                  class="text-truncate" 
                  v-on="on" 
                  @mouseover="setTooltipActivated($event, `${attrs.id}ListItem`)"
                >
                  {{ item.name }}
                </div>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </v-card>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  name: "CloudFileToUpload",
  props: {
    resetForm: { type: Boolean },
    submitFormComponent: { type: Boolean },
    currentDataValues: { type: Object },
    cloudFiles: { type: Array },
    maxFiles: {
      type: Number,
      default: 1
    }
  },
  data: () => ({
    selectedCloudFiles: [],
    tooltipActivated: {}
  }),
  computed: {
    fieldSelectCloudFile() {
      return {
        name: "cloudFile",
        label: i18n.t("labelCloudSelectFile"),
        validators: [(v) => (v && v.length > 0) || i18n.t("required")]
      };
    }
  },
  methods: {
    handleFileSelection(selectedFiles) {
      if (!selectedFiles || selectedFiles.length === 0) {
        this.selectedCloudFiles = [];
        this.$emit("cloudFile", null);
        return;
      }
      let outputSelectedFiles = [...selectedFiles];
      if (selectedFiles.length > this.maxFiles) {
        outputSelectedFiles = selectedFiles.slice(-this.maxFiles);
      }
      this.selectedCloudFiles = [...outputSelectedFiles];
      this.$emit("cloudFile", outputSelectedFiles[outputSelectedFiles.length - 1]);
    }
    ,
    isTooltipActivated(ref) {
      return this.tooltipActivated[ref];
    },
    setTooltipActivated(event, ref) {
      const { target } = event;
      this.$set(
        this.tooltipActivated,
        ref,
        target.offsetWidth < target.scrollWidth
      );
    },
    resetSelection() {
      this.selectedCloudFiles = [];
      if (this.$refs.cloudFileInput) {
        this.$refs.cloudFileInput.reset();
      }
      this.$emit("cloudFile", null);
    }
  },
  watch: {
    resetForm(newVal) {
      if (newVal) {
        this.resetSelection();
      }
    },
    submitFormComponent(val) {
      if (val && this.$refs.cloudFileInput) {
        this.$refs.cloudFileInput.hasFocused = true;
        this.$refs.cloudFileInput.validate();
      }
    },
    currentDataValues: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal) {
          const cloudFiles = Object.values(newVal)
            .filter(file => file.selectedFrom === 'cloud')
            .map(file => file.selectedFile);
          if (JSON.stringify(cloudFiles) !== JSON.stringify(this.selectedCloudFiles)) {
            this.$set(this, 'selectedCloudFiles', cloudFiles);
            if (this.$refs.cloudFileInput) {
              this.$refs.cloudFileInput.validate();
            }
          }
        } 
      }
    }

  }
};
</script>

<style scoped lang="scss">
.component-container {
  height: auto;
  min-height: 133px;
  width: 391px;
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #CCC;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.card-title {
  padding: 0;
  color: #117E8A;
  font-weight: 500;
  font-size: 14px;
}

.autocomplete {
  padding: 0;
  margin-top: 20px;
}

.v-chip {
  margin: 4px 0;
}

.itemTitle {
  height: 25px;
  
}
.v-select-list{
  max-width: 359px;

}
.v-list-item-content {
  padding: 0;
  width: 50px;
}

.autocomplete__chip-selection {
  max-width: 100%;
}

.autocomplete__multifiles-caption {
  color: #777777;
  font-size: 14px;
  font-style: italic;
  margin: .2em;
}
</style>