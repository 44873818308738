import i18n from "@/plugins/i18n";

export default {
  computed: {
    stepperConfig() {
      return {
        title: i18n.t("runProcess"),
      }
    },
    validation () {
      return [
        {
          title: i18n.t("selectFileToValidate"),
          component: "ImportFile",
          useFileToUploadForResume: true,
          config: {
            allowedFiles: 1,
            showSize:false,
            processType:'validate',
            allowedExtensionFiles:["csv", "xlsx"]
          }
        },
        {
          title: i18n.t("columnSeparatorStep"),
          component: "AutocompleteComponent",
          config: {
            formAttribute: 'delimiter',
            dataSourceAttr: 'delimiter',
          },
          resume: [
            {
              label: i18n.t("delimiterLabel"),
              valuekey: 'delimiter',
              translateValue: true,
            },
          ],
        },
        {
          title: i18n.t("selectInputDefinition"),
          component: "AutocompleteComponent",
          resume: [
            {
              label: i18n.t("inputsInfoLabel"),
              valuekey: 'descriptorName',
            },
          ],
          config: {
            indexedLabel: i18n.t("inputsInfoLabel"), // TODO clean this, the label must not be indexed one
            formAttribute: 'descriptorId',
            dataSourceAttr: 'inputDefinitionsData',
            noDataText: "noDataSelects.definitions",
          },
        },
        {
          title: i18n.t("selectFilters"),
          component: 'WithOrWithoutFilters',
          config: {
            dependsOnDataSource: true,
            usePreviousStepData: true,
            noDataText: "noDataSelects.filters",
            infoText: "definitionHasNoFilters",
            infoTextSecondary: "infoAlertFilters",
          },
          resume: [
            {
              label: i18n.t("filters"),
              valuekey: "selectedFilterNames",
              chips: true,
              showWhenNull: i18n.t("withoutFilters"),
            }
          ]
        },
      ]
    },
    transformation () {
      return [
        {
          title: i18n.t("selectFileToTransform"),
          component: "ImportFile",
          useFileToUploadForResume: true,
          config: {
            allowedFiles: 1,
            showSize:false,
            processType:'transform',
            allowedExtensionFiles:["csv", "xlsx"]
          }
        },
        {
          title: i18n.t("columnSeparatorStep"),
          component: "AutocompleteComponent",
          config: {
            formAttribute: 'delimiter',
            dataSourceAttr: 'delimiter',
          },
          resume: [
            {
              label: i18n.t("delimiterLabel"),
              valuekey: 'delimiter',
              translateValue: true,
            },
          ],
        },
        {
          title: i18n.t("selectInputDefinition"),
          component: "AutocompleteComponent",
          resume: [
            {
              label: i18n.t("inputsInfoLabel"),
              valuekey: 'descriptorName',
            },
          ],
          config: {
            indexedLabel: i18n.t("inputsInfoLabel"), // TODO clean this, the label must not be indexed one
            formAttribute: 'descriptorId',
            dataSourceAttr: 'inputDefinitionsData',
            noDataText: "noDataSelects.definitions",
          },
        },
        {
          title: i18n.t("selectFilters"),
          component: 'WithOrWithoutFilters',
          config: {
            dependsOnDataSource: true,
            usePreviousStepData: true,
            noDataText: "noDataSelects.filters",
            infoText: "definitionHasNoFilters",
            infoTextSecondary: "infoAlertFilters",
          },
          resume: [
            {
              label: i18n.t("filters"),
              valuekey: "selectedFilterNames",
              chips: true,
              showWhenNull: i18n.t("withoutFilters"),
            }
          ]
        },
        {
          title: i18n.t("selectOneOrMoreOutputDefinition"),
          component: 'SwitchAndMultipleSelection',
          config: {
            switch: {
              title: i18n.t("titleSwitchTransform"),
              label: i18n.t("addHeader"),
              defaultValue: true,
              positionLabel: "right",
              formAttribute: "displayHeader"
            },
            multipleSelection: {
              title: i18n.t("selectOutputDefinitionsTitle"),
              inputLabel: "OutputDefinitions",
              inputAttr: 'outputsInfo',
              dataSourceAttr: 'outputDefinitionsData',
              dataSourceIsObj: true,
              plusText: "definitions",
              noDataText: "noDataSelects.definitions",
            }
          },
          resume: [
            {
              label:  i18n.t("addHeader"),
              valuekey: 'displayHeader',
            },
            {
              label: i18n.t("OutputDefinitions"),
              key: "outputsInfo",
              valuekey: 'descriptorName',
              chips: true,
            },
          ],
        },
        {
          title: i18n.t("defineVariables"),
          component: "InputVariablesArrayForm",
        },
      ]
    },
    join () {
      return [
        {
          title: i18n.t("joinSelectType"),
          component: "JoinSelectType",
          resume: [
            {
              label: i18n.t("JoinProcess"),
              valuekey: 'joinType',
              translateValue: true,
              showWhenNull: true
            },
          ],
        },
        {
          title: i18n.t("selectFilesToJoin"),
          component: "ImportFile",
          useFileToUploadForResume: true,
          dependPreviousStepTitle: {
            attr: 'joinType',
            value: {
              INNER: i18n.t("selectFilesToJoin"),
              OUTER: i18n.t("selectFilesToJoinOuter"),
            }
          },
          config: {
            allowedFiles: 2,
            showSize: false,
            processType: 'join',
            allowedExtensionFiles: ['csv'],
            warningMsgCloudShowingFiles: true,
          },
        },
        {
          title: i18n.t("columnSeparatorStepJoin"),
          component: "StepParallelFields",
          config: {
            components: [
              {
                component: 'AutocompleteComponent',
                componentConfig: {
                  indexedLabel: 'joinFile',
                  formAttribute: 'delimiter',
                  dataSourceAttr: 'delimiter',
                },
              },
              {
                component: 'AutocompleteComponent',
                componentConfig: {
                  indexedLabel: 'joinFile',
                  formAttribute: 'delimiter',
                  dataSourceAttr: 'delimiter',
                  autofocus: false,
                },
              }
            ],
          },  
          resume: [
            {
              labelToTranslate: 'joinFile',
              labelAttr: 'fileIndex',
              valuekey: 'delimiter',
              translateValue: true,
            },
          ],
        },
        {
          title: i18n.t("selectInputDefinitionJoin"),
          component: "StepParallelFields",
          config: {
            components: [
              {
                component: 'AutocompleteComponent',
                componentConfig: {
                  indexedLabel: 'joinFile',
                  formAttribute: 'descriptorId',
                  dataSourceAttr: 'inputDefinitionsData',
                  noDataText: "noDataSelects.definitions",
                },
              },
              {
                component: 'AutocompleteComponent',
                componentConfig: {
                  indexedLabel: 'joinFile',
                  formAttribute: 'descriptorId',
                  dataSourceAttr: 'inputDefinitionsData',
                  noDataText: "noDataSelects.definitions",
                  autofocus: false,
                },
              }
            ],
          },
          resume: [
            {
              labelToTranslate: 'joinFile',
              labelAttr: 'fileIndex',
              valuekey: 'descriptorName',
            },
          ],
        },
        {
          title: i18n.t('joinKeyColumns'),
          component: "StepParallelFields",
          config: {
            multiRow: true,
            stepSubtitle: 'joinKeyColumnsSubtitle',
            addRowButtonLabel: 'joinKeyColumnsAdd',
            formAttributeOutput: 'primaryKeyColumns',
            components: [
              {
                component: 'AutocompleteComponent',
                componentConfig: {
                  indexedLabel: 'joinKeyColumnsField',
                  formAttribute: 'primaryKeyColumns',
                  dataSourceAttr: 'columnsFromInputDefinition',
                  noDataText: "noDataColumns",
                  usePreviousStepData: true,
                  watchCurrentData: true,
                },
              },
              {
                component: 'AutocompleteComponent',
                componentConfig: {
                  indexedLabel: 'joinKeyColumnsField',
                  formAttribute: 'primaryKeyColumns',
                  dataSourceAttr: 'columnsFromInputDefinition',
                  dataSourceIsObj: false,
                  noDataText: "noDataColumns",
                  usePreviousStepData: true,
                  autofocus: false,
                  watchCurrentData: true,
                },
              }
            ],
          },
          resume: [
            {
              label: "joinKeyColumnsField",
              labelAttr: 'keyIndex',
              secondLabelAttr: 'fileIndex',
              key: 'primaryKeyColumns',
              valuekey: 'value',
              multipleLines: true,
            },
          ],
        },
        {
          title: i18n.t("indicateFinalColumns"),
          component: "StepParallelFields",
          config: {
            checkValuesByDefault: true,
            components:
              [
                {
                  component: 'MultipleSelection',
                  componentConfig: {
                    indexedLabel: 'joinFileFinalColumns',
                    formAttribute: 'selectedColumns',
                    dataSourceAttr: 'columnsFromInputDefinition',
                    inputAttr: "selectedColumns",
                    usePreviousStepData: true,
                    useSecondPreviousStepData: 'primaryKeyColumns',
                    checkAllByDefault: true,
                    notRequired: true,
                  },
                },
                {
                  component: 'MultipleSelection',
                  componentConfig: {
                    indexedLabel: 'joinFileFinalColumns',
                    formAttribute: 'selectedColumns',
                    dataSourceAttr: 'columnsFromInputDefinition',
                    autofocus: false,
                    inputAttr: "selectedColumns",
                    usePreviousStepData: true,
                    useSecondPreviousStepData: 'primaryKeyColumns',
                    checkAllByDefault: true,
                    notRequired: true,
                  },
                }
              ],
          }
        },
      ]
    }
  },
};
