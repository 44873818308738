import { firebaseTools } from "@/mixins/firebase-tools"
import { tools } from "@/mixins/tools";
import store from '@/store';
import i18n from "@/plugins/i18n";
import { uploadBytesResumable } from "firebase/storage";

export const executeProcess = {
  mixins: [tools, firebaseTools],
  data() {
    return {
      mapTypes: {
        join: 'JOIN',
        validation: 'VALIDATE',
        transformation: 'VALIDATE_TRANSFORM',
      },
      progress: {}, 
    };
  },
  methods: {
    async onSubmitExecution({dataToSubmit, filesToUpload, processType}) {
      const processData = await this.createProcess(dataToSubmit, processType);

      const isValidationOk = await this.validateInformation(processData);

      if (isValidationOk.isValid) {
        const files = Array.isArray(filesToUpload) ? filesToUpload : [filesToUpload]
        const fileInstances = files.filter(file => file instanceof File);
        if (fileInstances.length) { // When is not from 'cloud'
          return this.uploadFiles(processData, fileInstances, processType);
        } else {
          const validatedFile = await this.validateFile(processData);
          if (validatedFile.isValid) return this.executeProcessAndChangeStatus(processData, processType);
          else return {...validatedFile};
        }
      } else {
        return {...isValidationOk}
      }
    },
    validateFile({ id: processId}) {
      const { lang: userLanguage = 'es' } = this.userLogged || {};
      const params = {
        processId,
        userLanguage,
      };
      return this.httpRequest(
        "get",
        [window.PROJECT_ENV.BASE_URL_SENDER + "validation/file", { params }],
      ).then(res => {
        const { level } = res || {};
        if (level === 'SUCCESS') {
          return { isValid: true }
        } else {
          return { isValid: false, response: res, processId }
        }
      })
    },
    createProcess(dataToSubmit, processType) {
      const {
        email,
        lang: language,
        displayName: name,
        photoURL,
      } = store.state.userLogged;
      const { id: group } = store.state.actualGroup;
      const user = { email, group, language, name, photoURL };
      let process = {
        ...dataToSubmit,
        status: "RUNNING",
        startDate: new Date(),
        processType: this.mapTypes[processType],
        numberReadPackages: 0,
        totalNumberPackages: -1,
        user,
      };

      return this.insertDocument("process", process)
        .then((response) => {
          return {
            id: response.id,
            process
          }
        })
        .catch((err) => console.error(err));
    },
    validateInformation({ id: processId, process }) {
      return this.updateDocument("process", processId, {
        ...process,
        status: "RUNNING",
      }).then(() => {
        const { lang: userLanguage = 'es' } = this.userLogged || {};
        const params = {
          processId,
          userLanguage,
        }
        return this.httpRequest(
          "get",
          [window.PROJECT_ENV.BASE_URL_SENDER + "validation/definition", { params }],
        ).then(res => {
          const { level } = res || {};
          if (level === 'SUCCESS') {
            return { isValid: true }
          } else {
            return { isValid: false, response: res, processId }
          }
        })
      });
    },
    async uploadFiles({ id: processId, process }, selectedFiles, processType) {
      await this.updateDocument("process", processId, {
        ...process,
        status: "UPLOADING",
      });

      const uploadPromises = selectedFiles.map((selectedFile) => {
        return new Promise((resolve, reject) => {
          const { name: inputFileName = "Untitled" } = selectedFile || {};
          const path = `${store.state.actualGroup.id}/input/${inputFileName}`;
          const fileToUploadRef = this.createStorageReference(path);
          /*
            https://firebase.google.com/docs/storage/web/upload-files?authuser=0
            documentation link
          */
          this.uploadTask = uploadBytesResumable(fileToUploadRef, selectedFile);

          this.uploadTask.on(
            "state_changed",
            (snapshot) => {
              const newProgress = Math.floor(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              if (!this.progress[processId]) this.$set(this.progress, processId, newProgress);
              if (newProgress > this.progress[processId]) {
                //TODO: review, if openning in new tab not working on processList
                this.progress[processId] = newProgress

                store.dispatch('setStatusProgress',{
                  progress: newProgress,
                  processId: processId,
                });

                switch (snapshot.state) {
                  case "running":
                    console.info("Upload is running");
                    break;
                }
              }
            },
            (error) => {
              this.removeProgress(processId);

              console.error("Error occurred during upload: ", error);
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case "storage/unauthorized":
                  this.handleFireBaseRequests(error.code);
                  break;
                case "storage/canceled":
                  console.log("CANCELLED");
                  break;

                default:
                  break;
              }
              reject(error);
            },
            () => {
              // Handle successful uploads on complete
              this.removeProgress(processId);
              resolve();
            }
          );
        });
      });

      return Promise.all(uploadPromises)
        .then(async () => {
          const validatedFile = await this.validateFile({ id: processId });
          if (validatedFile.isValid) return this.executeProcessAndChangeStatus({ id: processId, process }, processType);
          else return {...validatedFile};
        })
        .catch((error) => {
          console.error("Error occurred during upload:", error);
        });
    },
    executeProcessAndChangeStatus({ id: processId, process }, processType) {
      return this.updateDocument("process", processId, {
        ...process,
        status: "RUNNING",
      }).then(() => {
        if (processType !== 'join') {
          return this.executeProcess({ id: processId, process });
        } else {
          return this.executeJoinProcess({ id: processId });
        }
      }
      );
    },
    executeJoinProcess({ id: processId }) {
      const mergerUrl = window.PROJECT_ENV.URL_MERGER;
      const url = `${mergerUrl}?process_id=${processId}`;
      return this.httpRequest("post", [url]).then((response) => {
        return response
      }).catch((error) => {
        return this.handleHttpStatus(error);
      });
    },
    executeProcess({ id: processId }) {
      if (!processId) return;
      let params = new FormData();
      const { lang: userLanguage = 'es' } = store.state.userLogged || {};
      const { id: userGroupId } = store.state.actualGroup || {};
      params.append("processId", processId);
      params.append("userLanguage", userLanguage);
      params.append("userGroup", userGroupId);

      return this.httpRequest(
        "post",
        [window.PROJECT_ENV.BASE_URL_SENDER + "execute", params],
        false,
        true
      ).then((response) => {
        if (!response) {
          this.updateDocument("process", processId, { status: "FAIL" });
        }

        const isInProcessHistoryView = store.state.actualView.name === 'ProcessHistory';
        const linkToShowProcess = !isInProcessHistoryView ? { to: 'ProcessHistory', text: i18n.t('seeProcessStatus') } : null;
        const hasValidationError = ['VALIDATION_ERROR', 'FAIL'].includes(response?.level, response.data?.level);
        if (hasValidationError) {
          this.setShowSnackBar(false);
          const isInHomeView = store.state.actualView.name === 'Home';
          const isWarning = ["VALIDATION_ERROR"].includes(response?.level || response?.data?.level);

          let snackbarMsg = {
            color: isWarning ? "warning" : "error",
            icon: isWarning ? "mdi-alert" : "mdi-alert-circle",
            msg: (response.msg || response?.data?.msg) + ".",
            link: linkToShowProcess,
          }

          if (!isInHomeView) this.setShowSnackBar(snackbarMsg);

        } else {
          this.handleHttpStatus(response);
        }
        return response?.data || response;
      })
        .catch(error => {
          this.handleHttpStatus(error);
        })
    },
    removeProgress(processId) {
      this.$delete(this.progress, processId);
      store.dispatch('setStatusProgress',{
        processId,
        remove: true,
      });
    }
  }
}