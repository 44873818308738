import store from '@/store';
import i18n from '@/plugins/i18n';

export const authentication = {
  methods: {
    setUserLoggedState() {
      const userLogged = JSON.parse(localStorage.getItem("userLogged"));
      if (userLogged) {
        store.dispatch('setUserLogged', userLogged);
        i18n.locale = userLogged.lang || 'en';
      }
    },

    createUserSession(data) {
      // Store user logged
      const { uid: vuexUid } = store.state.userLogged;
      const { uid: dataUid } = data.userLogged;
      if (vuexUid === dataUid) {
        const newUserLogged = {
          ...store.state.userLogged,
          ...data.userLogged,
        };
        localStorage.setItem('userLogged', JSON.stringify(newUserLogged));

      } else {
        localStorage.setItem('userLogged', JSON.stringify(data.userLogged));
      }
      // Store the user's JWT
      localStorage.setItem('token', data.jwt);
      this.setUserLoggedState();
    },

    checkUserRoles(routeRoles) {
      if (routeRoles) {
        const userLoggedRoles = store.state.userLogged ? store.state.userLogged.roles : [];
        
        if (this.rolesDiff(routeRoles, userLoggedRoles).length) {
          // If user logged roles are not included in allowed roles -> deny access
          return false;
        }
      }
    
      return true;
    },
    
    rolesDiff(routeRoles, userLoggedRoles) {
      var rolesObj = routeRoles.reduce(function(map, obj) {
        map[obj] = true;
        return map;
      }, {});
    
      routeRoles.forEach(item => {
        if (userLoggedRoles.includes(item)) {
          delete rolesObj[item];
        }
      });
      return Object.keys(rolesObj);
    },
  }
}
