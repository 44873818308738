import Vue from 'vue'
import Vuex from 'vuex'
import { firebaseTools } from "../mixins/firebase-tools.js";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userLogged: undefined,
    userGroups: undefined,
    actualGroup: undefined,
    httpStatus: undefined,
    dataView: undefined,
    extraDataView: undefined,
    actualView: undefined,
    appMenu: undefined,
    menuOpen: undefined,
    loadingPage: 0,
    loadingData: false,
    envTheme: undefined,
    swUpdateExists: false,
    itemToAddEdit: undefined,
    itemToAddEditStepper: undefined,
    isAnyEditModalOpen: false,
    itemToImport: undefined,
    dictionaryToImport: undefined,
    itemToConfirmAction: undefined,
    showSnackBar: undefined,
    headerAndBreadcrumsHeight: undefined,
    processData: undefined,
    processesPerPage: 10,
    currentProcessPage: 1,
    totalProcesses: 0,
    inputDefinitionsData: undefined,
    outputDefinitionsData: undefined,
    dictionariesData: undefined,
    listsData: undefined,
    volatileData: undefined,
    unsubscribe: {
      InputDefinitions: undefined,
      OutputDefinitions: undefined,
      Dictionaries: undefined,
      Lists: undefined,
      ProcessHistory: undefined,
      userLogged: undefined,
      Detail: undefined,
      DetailDictionary: undefined,
    },
    itemToSelect: undefined,
    routeVuex:undefined,
    statusProgress: {},
    isDeletedDocument: false,
    isExecuteProcessOngoing: false,
  },
  mutations: {
    SET_USER_LOGGED(state, userLogged) {
      state.userLogged = userLogged;
    },
    SET_USER_GROUPS(state, userGroups) {
      state.userGroups = userGroups;
    },
    SET_ACTUAL_GROUP(state, actualGroup) {
      state.actualGroup = actualGroup;
    },
    SET_DATA_VIEW(state, dataView) {
      state.dataView = dataView;
    },
    SET_EXTRA_DATA_VIEW(state, extradataView) {
      if (extradataView) {
        if (!state.extraDataView) state.extraDataView = {};

        Object.keys(extradataView).forEach((key) => {
          Vue.set(state.extraDataView, key, extradataView[key]);
        });
      } else {
        state.extraDataView = extradataView;
      }
    },
    SET_ACTUAL_VIEW(state, actualView) {
      state.actualView = actualView;
    },
    SET_APP_MENU(state, appMenu) {
      state.appMenu = appMenu;
    },
    SET_MENU_OPEN(state, menuOpen) {
      state.menuOpen = menuOpen;
    },
    SET_LOADING_PAGE(state, loadingPage) {
      let result = state.loadingPage;
      if(loadingPage) ++result;
      else --result;
      state.loadingPage = Math.max(0, result);
    },
    SET_LOADING_DATA(state, loadingData) {
      state.loadingData = loadingData;
    },
    SET_SETS_STATUS(state, setsStatus) {
      state.setsStatus = setsStatus;
    },
    SET_ENV_THEME(state, envTheme) {
      state.envTheme = envTheme;
    },
    SET_SW_UPDATE_EXISTS(state, swUpdateExists) {
      state.swUpdateExists = swUpdateExists;
    },
    SET_ITEM_TO_ADD_EDIT(state, itemToAddEdit) {
      state.itemToAddEdit = itemToAddEdit;
    },
    SET_ITEM_TO_ADD_EDIT_STEPPER(state, itemToAddEditStepper) {
      state.itemToAddEditStepper = itemToAddEditStepper;
    },
    SET_IS_ANY_EDIT_MODAL_OPEN(state, isAnyEditModalOpen) {
      state.isAnyEditModalOpen = isAnyEditModalOpen;
    },
    SET_ITEM_TO_IMPORT(state, itemToImport) {
      state.itemToImport = itemToImport;
    },
    SET_ITEM_TO_CONFIRM_ACTION(state, itemToConfirmAction) {
      state.itemToConfirmAction = itemToConfirmAction;
    },
    SET_SHOW_SNACKBAR(state, showSnackBar) {
      state.showSnackBar = showSnackBar;
    },
    SET_HEADER_AND_BREADCRUMS_HEIGHT(state, headerAndBreadcrumsHeight) {
      state.headerAndBreadcrumsHeight = headerAndBreadcrumsHeight;
    },
    SET_PROCESS_DATA(state, processData) {
      state.processData = processData;
    },

    SET_INPUT_DEFINITIONS_DATA(state, inputDefinitionsData) {
      state.inputDefinitionsData = inputDefinitionsData;
    },
    SET_OUTPUT_DEFINITIONS_DATA(state, outputDefinitionsData) {
      state.outputDefinitionsData = outputDefinitionsData;
    },
    SET_DICTIONARIES_DATA(state, dictionariesData) {
      state.dictionariesData = dictionariesData;
    },
    SET_LISTS_DATA(state, listsData) {
      state.listsData = listsData;
    },
    SET_VOLATILE_DATA(state, volatileData) {
      state.volatileData = volatileData;
    },
    SET_UNSUBSCRIBE(state, unsubscribe) {
      state.unsubscribe = { ...state.unsubscribe, ...unsubscribe };
    },
    SET_HTTP_STATUS(state, httpStatus) {
      state.httpStatus = httpStatus;
    },
    SET_ITEM_TO_SELECT(state, itemToSelect) {
      state.itemToSelect = itemToSelect;
    },
    SET_ROUTE_VUEX(state, routeVuex) {
      state.routeVuex = routeVuex;
    },
    SET_ITEM_TO_IMPORT_DICTIONARY(state, dictionaryToImport) {
      state.dictionaryToImport = dictionaryToImport;
    },
    SET_STATUS_PROGRESS_PROCESS(state, { processId, progress, remove }) {
      if (remove) {
        delete state.statusProgress[processId];
        return;
      }
      state.statusProgress = {...state.statusProgress, [processId]: progress};
    },
    SET_PROCESSES_PER_PAGE(state, value) {
      state.processesPerPage = value;
    },
    SET_CURRENT_PROCESS_PAGE(state, value) {
      state.currentProcessPage = value;
    },
    SET_TOTAL_PROCESSES(state, total) {
      state.totalProcesses = total;
    },
    SET_IS_DELETED_DOCUMENT(state, isDeletedDocument) {
      state.isDeletedDocument = isDeletedDocument;
    },
    SET_IS_EXECUTE_PROCESS_ONGOING(state, isExecuteProcessOngoing) {
      state.isExecuteProcessOngoing = isExecuteProcessOngoing
    }
  },
  actions: {
    setUserLogged({ commit }, userLogged) {
      localStorage.setItem('userLogged', JSON.stringify(userLogged));
      commit("SET_USER_LOGGED", userLogged);
    },
    setUserGroups({ commit }, userGroups) {
      commit("SET_USER_GROUPS", userGroups);
    },
    setActualGroup({ commit }, actualGroup) {
      commit("SET_ACTUAL_GROUP", actualGroup);
    },
    setDataView({ commit }, dataView) {
      commit("SET_DATA_VIEW", dataView);
    },
    setExtraDataView({ commit }, extraDataView) {
      commit("SET_EXTRA_DATA_VIEW", extraDataView);
    },
    setActualView({ commit }, actualView) {
      if (actualView) {
        commit("SET_ACTUAL_VIEW", actualView);
      }
    },
    setAppMenu({ commit }, appMenu) {
      commit("SET_APP_MENU", appMenu);
    },
    setMenuOpen({ commit }, menuOpen) {
      commit("SET_MENU_OPEN", menuOpen);
    },
    setLoadingPage({ commit }, loadingPage) {
      commit("SET_LOADING_PAGE", loadingPage);
    },
    setLoadingData({ commit }, loadingData) {
      commit("SET_LOADING_DATA", loadingData);
    },
    setEnvTheme({ commit }, envTheme) {
      commit("SET_ENV_THEME", envTheme);
    },
    setSwUpdateExists({ commit }, swUpdateExists) {
      commit("SET_SW_UPDATE_EXISTS", swUpdateExists);
    },
    setItemToAddEdit({ commit }, itemToAddEdit) {
      firebaseTools.methods.checkIfSetStateValidatingToken(itemToAddEdit).then((setVuex) => {
        if (setVuex) {
          commit("SET_ITEM_TO_ADD_EDIT", itemToAddEdit);
          commit("SET_IS_ANY_EDIT_MODAL_OPEN", Boolean(itemToAddEdit));
        }
      });
    },
    setItemToImport({ commit }, itemToImport) {
      firebaseTools.methods.checkIfSetStateValidatingToken(itemToImport).then((setVuex) => {
        if (setVuex) commit("SET_ITEM_TO_IMPORT", itemToImport);
      });
    },
    setItemToAddEditStepper({ commit }, itemToAddEditStepper) {
      firebaseTools.methods.checkIfSetStateValidatingToken(itemToAddEditStepper).then((setVuex) => {
        if (setVuex) {
          commit("SET_ITEM_TO_ADD_EDIT_STEPPER", itemToAddEditStepper);
          commit("SET_IS_ANY_EDIT_MODAL_OPEN", Boolean(itemToAddEditStepper));
        }
      });
    },
    setIsAnyEditModalOpen({ commit }, isAnyEditModalOpen) {
      commit("SET_IS_ANY_EDIT_MODAL_OPEN", isAnyEditModalOpen);
    },
    setItemToConfirmAction({ commit }, itemToConfirmAction) {
      firebaseTools.methods.checkIfSetStateValidatingToken(itemToConfirmAction).then((setVuex) => {
        if (setVuex) commit("SET_ITEM_TO_CONFIRM_ACTION", itemToConfirmAction);
      });
    },
    setShowSnackBar({ commit }, showSnackBar) {
      commit("SET_SHOW_SNACKBAR", showSnackBar);
    },
    setHeaderAndBreadcrumsHeight({ commit }, headerAndBreadcrumsHeight) {
      commit("SET_HEADER_AND_BREADCRUMS_HEIGHT", headerAndBreadcrumsHeight);
    },
    setProcessData({ commit }, processData) {
      commit("SET_PROCESS_DATA", processData);
    },
    setInputDefinitionsData({ commit }, inputDefinitionsData) {
      commit("SET_INPUT_DEFINITIONS_DATA", inputDefinitionsData);
    },
    setOutputDefinitionsData({ commit }, outputDefinitionsData) {
      commit("SET_OUTPUT_DEFINITIONS_DATA", outputDefinitionsData);
    },
    setDictionariesData({ commit }, dictionariesData) {
      commit("SET_DICTIONARIES_DATA", dictionariesData);
    },
    setListsData({ commit }, listsData) {
      commit("SET_LISTS_DATA", listsData);
    },
    setVolatileData({ commit }, volatileData) {
      commit("SET_VOLATILE_DATA", volatileData);
    },
    setUnsubscribe({ commit }, unsubscribe) {
      commit("SET_UNSUBSCRIBE", unsubscribe);
    },
    setHttpStatus({ commit }, httpStatus) {
      commit("SET_HTTP_STATUS", httpStatus);
    },
    setItemToSelect({ commit }, itemToSelect) {
      commit("SET_ITEM_TO_SELECT", itemToSelect);
    },
    setRouteVuex({ commit }, routeVuex) {
      commit("SET_ROUTE_VUEX", routeVuex);
    },
    setItemToImportDictionary({ commit }, dictionaryToImport) {
      firebaseTools.methods.checkIfSetStateValidatingToken(dictionaryToImport).then((setVuex) => {
        if (setVuex) commit("SET_ITEM_TO_IMPORT_DICTIONARY", dictionaryToImport);
      });
    },
    setStatusProgress({ commit }, statusProgress) {
      commit('SET_STATUS_PROGRESS_PROCESS', statusProgress);
    },
    setProcessesPerPage({ commit }, value) {
      commit('SET_PROCESSES_PER_PAGE', value);
    },
    setCurrentProcessPage({ commit }, value) {
      commit('SET_CURRENT_PROCESS_PAGE', value);
    },
    setTotalProcesses({ commit }, total) {
      commit('SET_TOTAL_PROCESSES', total);
    },
    setIsDeletedDocument({ commit }, isDeletedDocument) {
      commit('SET_IS_DELETED_DOCUMENT', isDeletedDocument)
    },
    setIsExecuteProcessOngoing({ commit }, isExecuteProcessOngoing) {
      commit('SET_IS_EXECUTE_PROCESS_ONGOING', isExecuteProcessOngoing);
    }
  }
})
