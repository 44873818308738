<template>
  <v-card>
    <template v-if="loadingParent">
      <ExecuteSkeleton />
    </template>
    <template v-else >
      <v-alert v-if="showAlert"
        outlined
        type="error"
        icon="mdi-alert-circle"
        color="error"
        class="mt-5"
      >
        <span class="validationMessage">
          {{ validationMsg}}
          <a @click="downloadValidationFile" v-if="validationFile"> {{validationFile.message}}</a>
        </span>
        
      </v-alert>
        <v-stepper
          vertical
          flat
          v-model="currentStep"
          :style= "{'max-height': stepperHeight}"
        >
        <template v-for="(step, i) in steps">
          <v-stepper-step
            :complete="isComplete(i)"
            :step="i + 1"
            :key="`${i}-step`"
            @click.stop.prevent="selectStep(i + 1)"
          >
            <template v-if="step.dependPreviousStepTitle && currentDataValues[i - 1]">
              {{ getDependentTitle(step.dependPreviousStepTitle, currentDataValues[i - 1]) }}
            </template>
            <template v-else>
              {{ step.title }}
            </template>
          </v-stepper-step>
          <v-stepper-content 
            v-if="currentStep > (i + 1)"
            :step="i"
            :key="`${i}-contentResume`"
            class="step-resumed"     
          >
            <ResumeExecution
              v-if="currentStep > (i + 1)"
              :currentDataValues="currentDataValues[i] || {}"
              :step="step"
              :processType="processType"
            />
          </v-stepper-content>
          <v-stepper-content  
            :complete="isComplete(i)"
            :step="i + 1"
            :key="`${i}-content`"
            :class="{ 'hide-step-content': currentStep !== i + 1 && isComplete(i) }"
          >
            <section class="stepper__content">
              <component
                v-if="currentStep === i + 1"
                :class="{'component-wrapper': processType !== 'join' && step.component !== 'ImportFile'}"
                :hasTrafisCloudBtn="true"
                :key="currentStep"
                :is="steps[i].component"
                :submitFormComponent="submitFormComponent"
                :currentDataValues="currentDataValues[currentStep - 1]"
                :previousStepData="previousStepData"
                :secondPreviousData="secondPreviousData"
                :resetComponent="resetComponent"
                :config="steps[i].config"
                @isValid="isValid = $event"
                @dirty="onDirty"
                @update="updateValues($event, i)"
                @setValuesByDefault="valuesByDefault = $event"
                :dataSource="dataSource"
                :showCardFiles="true"
                :hasReference="currentDataValues && currentDataValues?.some(data => data?.joinType === 'OUTER')"
              />
  
              <section class="stepper-buttons__wrapper">  
                <v-btn
                  class="stepper-buttons__cancel"
                  rounded
                  outlined
                  @click="selectStep(currentStep - 1)"
                >
                  <v-icon left>{{ currentStep === 1 ? 'mdi-close' : 'mdi-chevron-left' }}</v-icon>
                  {{ currentStep === 1 ? $t("cancel") : $t("goBack") }}
                </v-btn>
                <v-btn
                  @click="onClickLastButton()"
                  color="primary"
                  rounded
                  depressed
                >
                  <template v-if="isLastStep">
                    <v-icon left>mdi-play</v-icon>
                    {{ $t("execute") }}
                  </template>
                  <template v-else>
                    {{ $t("continue") }}
                    <v-icon right>mdi-chevron-right</v-icon>
                  </template>
                </v-btn>
              </section>
            </section>
          </v-stepper-content>
        </template>
      </v-stepper>
    </template>
  </v-card>
</template>

<script>
import executeConfigStepper from "@/mixins/execute-config-stepper";
import AutocompleteComponent from '../stepperComponents/AutocompleteComponent.vue';
import InputVariablesArrayForm from '../stepperComponents/InputVariablesArrayForm.vue';
import ImportFile from '../stepperComponents/ImportFile.vue';
import WithOrWithoutFilters from '../stepperComponents/WithOrWithoutFilters.vue';
import MultipleSelection from '../stepperComponents/MultipleSelection.vue';
import SwitchAndMultipleSelection from '../stepperComponents/SwitchAndMultipleSelection.vue';
import JoinSelectType from '../stepperComponents/JoinSelectType.vue';
import StepParallelFields from "../stepperComponents/StepParallelFields.vue";
import ResumeExecution from '../stepperComponents/ResumeExecution.vue';
import { tools } from "@/mixins/tools";
import { firebaseTools } from "@/mixins/firebase-tools.js";
import { executeProcess } from '../mixins/execute-process';
import { mapState, mapActions } from "vuex";
import i18n from "@/plugins/i18n";
import ExecuteSkeleton from "../skeletonComponents/ExecuteSkeleton.vue";

export default {
  name: 'ExecuteProcess',
  mixins: [tools, firebaseTools, executeProcess],
  components: {
    AutocompleteComponent,
    InputVariablesArrayForm,
    ImportFile,
    WithOrWithoutFilters,
    MultipleSelection,
    SwitchAndMultipleSelection,
    JoinSelectType,
    StepParallelFields,
    ResumeExecution,
    ExecuteSkeleton
  },
  props: {
      loadingParent: { type: Boolean, default: false },
  },
  data() {
    return {
      currentStep: 1,
      currentDataValues: [],
      submitFormComponent: false,
      resetComponent: false,
      isValid: true,
      dirty: false,
      processType: 'join',
      filesToUpload: [],
      valuesByDefault: null,
      showAlert:false,
      validationMsg: undefined,
      validationFile: null,
    };
  },
  computed: {
    ...mapState([
      "userLogged",
      "actualGroup",
      "actualView",
      "isExecuteProcessOngoing"
    ]),
    steps() {
      return (executeConfigStepper.computed[this.processType]() || []);
    },
    isLastStep() {
      return this.currentStep === this.steps.length;
    },
    previousStepData() {
      return (this.currentStep - 2 >= 0 && this.currentDataValues[this.currentStep - 2]) || undefined;
    },
    secondPreviousData() {
      return (this.currentStep - 3 >= 0 && this.currentDataValues[this.currentStep - 3]) || undefined;
    },
    dataSource() {
      return this.getDataSource();
    },
    // height: viewport - header - bredcrumbs - padding/margin - (v-if) alert
    stepperHeight() {
      return this.showAlert ? 'calc(100vh - 64px - 61px - 40px - 58px)' : 'calc(100vh - 64px - 61px - 40px)';
    },
  },
  created() {
    this.resetValues();
    this.setIsExecuteProcessOngoing(true);
  },
  destroyed() {
    this.setIsExecuteProcessOngoing(false);
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler (val) {
        this.processType = val.split("/").pop();
      }
    },
    isExecuteProcessOngoing(newValue) {
      if (!newValue) {
        this.resetValues();
        this.$nextTick(() => {
          this.setIsExecuteProcessOngoing(!newValue);
        })
      }
    },
    resetComponent (val) {
      if (val) {
        this.$nextTick(() => {
          this.resetComponent = false;
        });  
      }
    },
    actualGroup() {
      this.resetValues();
    },
  },
  methods: {
    ...mapActions([
      "setIsExecuteProcessOngoing",
    ]),
    getDependentTitle(titleConfig, valueItDependsOn) {
      const { value, attr } = titleConfig || {};
      return value[valueItDependsOn[attr]]
    },
    onDirty(ev) {
      const { dirty } = typeof ev === 'boolean' ? { dirty: ev } : ev;
      this.dirty = dirty;
    },
    selectStep(targetStep) {
      if (!targetStep) return this.cancel()
      this.submitFormComponent = true;
      if (targetStep <= this.currentStep) return (this.currentStep = targetStep);
      this.submitFormComponent = true;
   

      this.$nextTick(() => {
        if (targetStep === this.currentStep + 1 && this.isValid) {
          this.currentStep = targetStep;
        }
        this.submitFormComponent = false;
      });
    },
    isComplete(i) {
      return this.currentStep > i + 1;
    },
    cancel() {
      const dirty = this.dirty;
      this.executeAction({
        action: "cancel",
        section: "executeProcess",
        dirty,
        msg: {
          title: 'discardExecuteProcessTitle',
          text: 'discardExecuteProcessText',
        },
      })
    },
    resetValues() {
      this.currentDataValues = [];
      this.filesToUpload = [];
      this.resetComponent = true;
      this.isValid = true;
      this.dirty = false;
      this.currentStep = 1;
      this.validationFile = null;
    },
    onClickLastButton() {
      this.isLastStep ? this.onSubmit() : this.selectStep(this.currentStep + 1)
    }, 
    updateValues(event, index) {
      this.submitFormComponent = false;
      this.$set(this.currentDataValues, index, event);
      this.showAlert = false;  
      this.validationMsg = undefined;
      this.validationFile = null;
      this.checkValuesToRemove();
    },
    checkValuesToRemove() {
      if (!this.hasFilledValues('outputsInfo') && this.hasFilledValues('variables')) {
        this.currentDataValues = this.currentDataValues.filter((step) => !step?.variables);
      }
    },
    hasFilledValues(attr) {
      return this.currentDataValues.some((step) => step?.[attr]);
    },
    checkValuesByDefault() {
      const existValueLastStep = this.currentDataValues.length === this.currentStep;
      const lastStepConfig = this.steps[this.currentStep - 1]?.config;
      if (existValueLastStep && lastStepConfig.checkValuesByDefault) {
        let valuesByDefault = this.currentDataValues[this.currentStep - 1];
        // eslint-disable-next-line no-unused-vars
        const allValuesAreNull = Object.entries(valuesByDefault).every(([_, value]) =>  value === null);
        if (allValuesAreNull) {
          this.$set(this.currentDataValues, this.currentStep - 1,this.valuesByDefault);
        }
      }
    },
    async onSubmit() {
      this.submitFormComponent = true;
      let dataToSubmit = null;
      this.$nextTick(async() => {
        this.submitFormComponent = false;
        if (!this.isValid) return;

        if (this.processType === 'validation') {
          dataToSubmit = this.transformToValidationModel();
        } else if ( this.processType === 'transformation') {
          dataToSubmit = this.transformToTransformationModel();
        } else {
          this.checkValuesByDefault();
          dataToSubmit = this.transformToJoinModel();
        }
        
        const onSubmitArgs = {
          dataToSubmit,
          filesToUpload: this.filesToUpload,
          processType: this.processType,
        };
        await firebaseTools.methods.checkIfSetStateValidatingToken(true, true)

        this.onSubmitExecution(onSubmitArgs).then((res) => {
          const { level, data, msg, status } = res?.response || res || {};
          if (level === 'SUCCESS' || status === 'success') {
            this.resetValues();
            return true
          }
          if (level === 'VALIDATION_ERROR') {
            this.validationFile = {
              name: data,
              processId: res.processId,
              message: msg,
            }
            this.validationMsg = i18n.t("validationFileError");
          }
          //TODO pendiente que back mande el level.res
          if (level === 'FAIL') {
            this.validationMsg = msg;
          }
          this.showAlert = !!this.validationMsg;
        });
      })
    },
    transformArrayToObj(array) {
      return array.reduce(
        (result, stepCurrentValues) => {
          /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
          const { selectedFrom, ...stepValue } = stepCurrentValues || {};
          return {
            ...result,
            ...stepValue,
          };
        }, {});
    },
    transformToValidationModel(stepsToValidation = this.currentDataValues) {
      let transformToObject = this.transformArrayToObj(stepsToValidation);
      
      transformToObject.fileName = transformToObject.file_0.selectedFile.name;
      if (transformToObject.file_0.selectedFile instanceof File ) {
        this.filesToUpload = transformToObject.file_0.selectedFile; 
      }
      this.$delete(transformToObject, 'file_0');

      return { inputsInfo: [{...transformToObject}] };
    },
    transformToTransformationModel() {
      const stepsToValidationModel = this.currentDataValues.slice(0,4);
      const stepsToTransformation = this.currentDataValues.slice(-2);
      const dataTransformedInputs = this.transformToValidationModel(stepsToValidationModel);
      const dataTransformedOutputs = this.transformArrayToObj(stepsToTransformation);
      return {...dataTransformedInputs, ...dataTransformedOutputs}
    },
    transformToJoinModel() {
      const allDataStepsButFirst = this.currentDataValues.slice(1);
      const fileKeys = Object.keys(allDataStepsButFirst[1]);

      const transformedArray = fileKeys.map((fileKey) => {
        return allDataStepsButFirst.reduce(( result, {[fileKey]: stepData}) => {
          return {...result, ...stepData};
        }, {})
      }).map(el => {
        let elToReturn = {...el};
        elToReturn.fileName = el.selectedFile.name;
        if (el.selectedFile instanceof File) {
          this.filesToUpload.push(el.selectedFile);
        }
        delete elToReturn.selectedFile;
        delete elToReturn.selectedFrom;

        // TODO: when HU optional header is done, must be reviewed
        elToReturn.hasHeader = true; 
        if (el.primaryKeyColumns) {
          elToReturn.primaryKeyColumns = el.primaryKeyColumns.map(primaryKey => ({
              ...primaryKey,
              key: String(primaryKey.key)
          }));
        }
        return elToReturn;
      });
      // Always at least one file is reference
      if (!transformedArray.some(el => el.isReference)) transformedArray[0].isReference = true;
      
      return { inputsInfo: [...transformedArray], joinType: this.currentDataValues[0].joinType };
    },
    downloadValidationFile() {
      this.loading = true;
      const { processId, name } = this.validationFile;
      const path = `${this.actualGroup.id}/output/${processId}/${name}`;
      const fileRef = this.createStorageReference(path);

      this.downloadStorageFile(fileRef, path).finally(() => {
        this.loading = false;
      });
    }
  },
};
</script> 

<style lang="scss" scoped>

.v-card{
  padding-top: 1px;
}
.v-stepper {
  // viewport - header - bredcrumbs - padding/margin - alert
  height: fit-content;
  overflow: auto;
  .hide-step-content{
   border-left: 1px solid transparent!important;
   transition: border 0s!important;
  }
  ::v-deep {
    .v-stepper__content {
      width: calc(100% - 36px);
      padding: 16px 20px 16px 23px;
      &.tab-transition-leave.step-resumed,
      &.tab-transition-leave-active.step-resumed,
      &.tab-transition-leave-to.step-resumed,
      &.tab-reverse-transition-leave-active.step-resumed,
      &.tab-reverse-transition-leave-to.step-resumed {
        display: none;
      }
    }
  }
}

.stepper__content {
  background-color: var(--menuGray);
  padding: 20px;
  border-radius: 10px;
  .stepper-buttons__cancel {
    background-color: var(--white);
  }
  .stepper-buttons__wrapper {
    display: inline-flex;
    gap: 10px;
    margin-top: 20px;
    .v-btn {
      font-weight: 400;
    }
  }
}

::v-deep {
  .v-stepper__step {
    .v-stepper__label {
      font-size: 16px;
      font-weight: 500;
      color: var(--fontColorSecondary)
    }
    &.v-stepper__step--complete {
      .v-stepper__label {
        color: var(--fontColor);
        font-weight: 400;
      }
    }

    &.v-stepper__step--inactive {
      .v-stepper__label {
        color: var(--fontColorTerciary);
        font-weight: 400;
      }
    }
  }
}
.import-file {
  ::v-deep .dropArea {
    padding: 30px 70px;
    text-wrap: balance;
  }
}

.step-resumed {
  ::v-deep .v-stepper__wrapper {
    padding: 0;
    height: auto !important;
    .resume-wrapper {
      padding: 0;
    }
  }
}
.v-alert{
  width: auto;
  margin-left: 24px;
  font-size: 16px;
  display: inline-flex;
}
.validationMessage a {
  text-decoration: underline;
  color:var(--red);
}
.component-wrapper {
  max-width: 400px;
}
:deep(.v-alert__icon) {
  margin-right: 10px !important;
}

</style>