<template>
  <div>
    <v-alert
      v-if="infoText"
      outlined
      type="info"
      color="info"
      icon="mdi-information"
      class="mb-5 infoTextAlert"
      >{{ $t(infoText) }}</v-alert
    >
    <v-form ref="multipleSelection" @submit.prevent>
      <v-autocomplete
        v-model="inputValue"
        :items="inputDataSource"
        :label="inputLabel"
        multiple
        clearable
        :return-object="configInput.dataSourceIsObj"
        :rules="[(v) => requiredRule(v, notRequired)]"
        @input="update"
        @change="onChange"
        :item-text="configInput.itemText"
        :menu-props="{ contentClass: 'style-content' }"
        :no-data-text="$t(noDataText)"
        @update:search-input="setSearchText"
        :autofocus="autofocus"
        ref="autocomplete"
      >
        <template
          v-if="filteredItems && filteredItems.length"
          v-slot:prepend-item
        >
          <v-list-item ripple @mousedown.prevent @click="toggle()">
            <v-list-item-action class="mr-3">
              <v-icon :color="inputValue.length > 0 ? 'primary' : ''">
                {{ iconPrependItem }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("selectAll") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item="{ attrs, item }">
          <v-list-item-action class="mr-3">
            <v-checkbox v-model="attrs.inputValue" :ripple="false" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <v-tooltip
                bottom
                :disabled="!isTooltipActivated(`${attrs.id}ListItem`)"
              >
                <template v-slot:activator="{ on }">
                  <div
                    class="text-truncate"
                    v-on="on"
                    @mouseover="
                      setTooltipActivated($event, `${attrs.id}ListItem`)
                    "
                  >
                    {{ getValueItem(item) }}
                  </div>
                </template>
                <span>{{ getValueItem(item) }}</span>
              </v-tooltip>
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip
            v-if="index === 0"
            small
            :color="'var(--borderGray)'"
            :text-color="'var(--darkGray)'"
          >
            <span v-if="inputValue.length === inputDataSource.length">{{
              $t("all")
            }}</span>
            <span v-else>{{ item.descriptorName || item }}</span>
          </v-chip>
          <span
            v-if="index === 1 && inputValue.length !== inputDataSource.length"
            class="plusElement"
          >
            {{
              $t("plusNElements", {
                number: inputValue.length - 1,
                element: $t(configInput.plusText).toLowerCase(),
              })
            }}
          </span>
        </template>
      </v-autocomplete>
    </v-form>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import { tools } from "../mixins/tools";


export default {
  name: "MultipleSelection",
  mixins: [tools],
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    currentDataValues: { type: Object },
    submitFormComponent: { type: Boolean },
    resetComponent: { type: Boolean },
    previousStepData: { type: Object },
    secondPreviousData: { type: Object },
  },
  data: () => ({
    inputValue: [],
    dirty: false,
    requiredRule: (v, notRequired) =>
      !!(v && v.length) || notRequired || i18n.t("required"),
    defaultConfigOptions: {
      inputLabel: "filters",
      inputAttr: "selectedFilterNames",
      dataSourceAttr: "filtersFromInputDefinition",
      plusText: "filters",
      itemText: "descriptorName",
    },
    tooltipActivated: {},
    searchText: null,
    configInput: {},
    inputDataSource: [],
  }),
  computed: {
    autofocus() {
      return this.configInput.autofocus === false ? this.configInput.autofocus : true;
    },
    inputLabel() {
      return this.configInput.indexedLabel || this.$t(this.configInput.inputLabel);
    },
    inputAttr() {
      return this.configInput.inputAttr;
    },
    infoText() {
      let text = this.configInput?.infoText;
      if (this.dependsOnDataSource && this.inputDataSource.length) {
        text = this.inputDataSource.length ? this.configInput.infoTextSecondary : this.configInput.infoText;
      }
      return text;
    },
    noDataText() {
      return this.config.noDataText ?? "noDataAutocomplete";
    },
    notRequired() {
      return this.configInput.notRequired;
    },
    dependsOnDataSource() {
      return this.configInput.dependsOnDataSource;
    },
    iconPrependItem() {
      if (this.isAllItemsSelected) return "mdi-checkbox-marked";
      else if (this.inputValue.length > 0 && !this.isAllItemsSelected)
        return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    filteredItems() {
      if (!this.searchText) return this.inputDataSource;
      const filteredItems = this.inputDataSource.filter((item) => {
        const isObject = this.configInput.dataSourceIsObj;
        const valueKey = this.configInput.itemText;
        const itemText = isObject
          ? item[valueKey].toLowerCase()
          : item.toLowerCase();
        const search = this.searchText.toLowerCase();

        return itemText.includes(search);
      });
      return filteredItems;
    },
    itemsKeySelected() {
      return this.inputValue.map((item) => this.getItemKey(item));
    },
    isAllItemsSelected() {
      return this.filteredItems.every((item) =>
        this.itemsKeySelected.includes(this.getItemKey(item))
      );
    },
  },
  watch: {
    dirty(val) {
      this.$emit("dirty", val);
    },
    submitFormComponent(val) {
      if (val) this.$emit("isValid", this.validateInput());
    },
    resetComponent(val) {
      if (val) {
        this.inputValue = [];
        this.dirty = false;
      }
    },
    config: {
      handler(val) {
        this.configInput = { ...this.defaultConfigOptions, ...val };
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.inputDataSource = this.getInputDataSource();
    const hasValue = Boolean(this.inputDataSource.length > 0 && this.currentDataValues?.[this.inputAttr]?.length);
    const checkAllAsDefault = this.config?.checkAllByDefault;
    let defaultSelected = []
    if (!hasValue && checkAllAsDefault) {
      defaultSelected = this.inputDataSource;
      this.$emit('setValuesByDefault', defaultSelected)
    }
    this.inputValue = hasValue ? this.currentDataValues?.[this.inputAttr] : defaultSelected;
    this.$nextTick(() => {
      this.update(this.inputValue, true);
    });
  },
  mounted() {
    if (!this.inputDataSource?.length) this.getInputDataSource();
  },
  methods: {
    // TODO: revisar con la tarea 'Poder ejecutar sólo con teclas del teclado'
    /* openMenu() {
      if (this.$refs.autocomplete && this.$refs.autocomplete.$refs.menu) {
        this.$refs.autocomplete.$refs.menu.isActive = true;
      }
    }, */
    setSearchText(event) {
      this.searchText = event;
    },
    getValueItem(item) {
      return this.configInput.dataSourceIsObj ? item.descriptorName : item;
    },
    setTooltipActivated(event, ref) {
      const { target } = event;
      this.$set(
        this.tooltipActivated,
        ref,
        target.offsetWidth < target.scrollWidth
      );
    },
    isTooltipActivated(ref) {
      return this.tooltipActivated[ref];
    },
    update(event, fromCreated = false) {
      const isValid = !fromCreated ? this.validateInput() : true;
      const valueToEmmit =
        isValid && event.length ? { [this.inputAttr]: event } : null;
      this.$emit("isValid", isValid);
      this.$emit("update", valueToEmmit);
    },
    onChange() {
      this.dirty = true;
    },
    validateInput() {
      return this.$refs.multipleSelection?.validate();
    },
    getItemKey(item) {
      return this.configInput.dataSourceIsObj ? item.descriptorId : item;
    },
    toggle() {
      this.dirty = true;
      const isSelectingAll = !this.isAllItemsSelected;
      if (isSelectingAll) {
        const itemsToAdd = this.filteredItems.filter(
          (item) => !this.itemsKeySelected.includes(this.getItemKey(item))
        );
        this.inputValue = [...this.inputValue, ...itemsToAdd];
      } else {
        const filteredKeys = this.filteredItems.map((item) =>
          this.getItemKey(item)
        );
        this.inputValue = this.inputValue.filter(
          (item) => !filteredKeys.includes(this.getItemKey(item))
        );
      }
      this.$nextTick(() => {
        this.update(this.inputValue);
      });
    },
    getInputDataSource() {
      const { dataSourceAttr, dataSourceIsObj, usePreviousStepData, useSecondPreviousStepData } = this.configInput;
      let dataBasedOn = null;
      const usePreviousData = useSecondPreviousStepData ?? usePreviousStepData;
      const previousData = (Boolean(useSecondPreviousStepData) && this.secondPreviousData) || usePreviousStepData && this.previousStepData;

      if (usePreviousData && previousData) {
        dataBasedOn = previousData;
      }
      let dataSource = (
        this.getDataSource(dataBasedOn)[dataSourceAttr] || []
      ).map(({ name, id, alias }) => {
        return dataSourceIsObj ? { descriptorId: id, descriptorName: name } : (name || alias);
      });
      if (useSecondPreviousStepData && usePreviousData && this.previousStepData) {
        dataSource = dataSource.filter(el => !this.previousStepData[useSecondPreviousStepData].some(({value}) => value === el))
      }
      return dataSource;
    },
  },
};
</script>

<style lang="scss" scoped>
.plusElement {
  color: var(--fontColorTerciary);
  font-size: 14px;
  font-style: italic;
}

.infoTextAlert {
  font-size: 16px;
  max-width: fit-content;
  & + .v-form {
    max-width: 400px;
  }
}
</style>
